import React, {useState,useEffect} from "react";
import HeaderUser from "../components/common/HeaderUser.component";
import Sidebar from "../components/common/Sidebar.component";
import makeTheApiCall from '../Api/axiosConfig'
import apiPath from '../Api/apiPath';
import {toast} from 'react-toastify'
import {useHistory} from 'react-router-dom'

function SmsLogs() {
  const history= useHistory();
  const [TableData, setTableData] = useState(null);

  const [ProjectList, setProjectList] = useState(null);
  const [AssignedId, setAssignedId] = useState(null);
  
  useEffect(() => {
    let apiOptions = {
      url :apiPath.Project_DropDown,
      data :{
        page: 1,
        limit: 1000,
      },
      method:"post"
    };
    makeTheApiCall(apiOptions).then((response)=>{
      let responseObj = response.success ? response?.success?.data : null;
      if(responseObj){
        setProjectList(responseObj)
      }
    });
  }, []);

  useEffect(() => {
    let apiOptions = {
      url :apiPath.SMS_LIST,
      data :{
        page: 1,
        limit: 1000,
        project_id:AssignedId
      },
      method:"post"
    };
    makeTheApiCall(apiOptions).then((response)=>{
      let responseObj = response.success ? response?.success?.data : null;
      if(responseObj){
        setTableData(responseObj)
      }
    });
  }, [AssignedId]);
  return (
    <div className="home">
      <HeaderUser />
      <Sidebar />
      <div className="employeeWraper">
        <div className="headingSec">
          <h1>Sms Logs</h1>
        </div>

        <div className="employyTable">
        <select name="project_id" className="left mr-2" onChange={e=>setAssignedId(e.target.value)}>
            <option value="">Select Project</option>
            {
              ProjectList && ProjectList?.map((list,key)=>{
                return (
                  <option value={list._id}>{list?.name}</option>
                )
              })
            }
          </select>
          <table>
            <thead className="">
              <tr className="">
              <th className="">#</th>
                <th className="">Sender</th>
                <th className="">Status</th>
               
              </tr>
            </thead>
            <tbody>
            {
                TableData && TableData?.map((obj,index)=>{
                  return (
                    <tr key={index}>
                      <d>{index+1}</d>
                      <td> {obj?.sender_mobile}</td>
                    <td>
                    <div class="form-check form-switch">
                       
                        <label class="form-check-label" for={obj?._id}>{obj?.status ? "success" : "failed"}</label>
                    </div>
                    </td>
                    
                  </tr>
                  )
                })
              }
              <tr>
                <td className="blankTd"> </td>
              </tr>
              
            </tbody>
          </table>

          {/* <div className="listingHeader">
           
          </div>
          <div className="tableBody">
            <div className="historyListing">
            
            </div>
            
          </div> */}
        </div>
      </div>
    </div>
  );
}

export default SmsLogs;
