import React from "react";
import { Link } from "react-router-dom";

function Button({ type, className, style, text, formText, to, onClick }) {
  switch (type) {
    case "secondary":
      className
        ? (className += " dc-btn-secondary")
        : (className = "dc-btn-secondary");
      //className = "dc-btn-secondary " + className || "" ;
      break;

    case "tertiary":
      className
        ? (className += " dc-btn-tertiary")
        : (className = "dc-btn-tertiary");
      break;

    default:
      className
        ? (className += " dc-btn-primary")
        : (className = "dc-btn-primary");
  }

  return (
    <Link
      data-dismiss="modal"
      to={to ? to : ""}
      className={`dc-btn ${className}`}
      style={{ ...style }}
      onClick={onClick}
    >
      {text ? text : "temp"}
    </Link>
  );
}

export default Button;
