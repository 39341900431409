import React, {useState,useEffect} from "react";
import HeaderUser from "../components/common/HeaderUser.component";
import Sidebar from "../components/common/Sidebar.component";
import makeTheApiCall from '../Api/axiosConfig'
import apiPath from '../Api/apiPath';
import {toast} from 'react-toastify'
import {useHistory} from 'react-router-dom';

function AllSubAdmin() {
  const history= useHistory();
  const [TableData, setTableData] = useState(null);

  const DeleteProject = (id)=>{
    let response = window.confirm('Are you sure you want to delete ?');
    if(response){
      let apiOptions = {
        url :apiPath.Delete_User,
        data :{
          user_id:id
        },
        method:"post"
      };
      makeTheApiCall(apiOptions).then((response)=>{
        let responseObj = response.success ? response?.success?.data : null;
        if(responseObj){
          toast.success('User has been deleted');
          setTimeout(() => {
            window.location.reload();
          }, 2000);

        }else{
          toast.error('Please try again later');
        }
      });
    }
  };
  
  useEffect(() => {
    let apiOptions = {
      url :apiPath.getUserListing,
      data :{
        page: 1,
        limit: 1000
      },
      method:"post"
    };
    makeTheApiCall(apiOptions).then((response)=>{
      let responseObj = response.success ? response?.success?.data : null;
      if(responseObj){
        setTableData(responseObj)
      }
    });
  }, []);
  return (
    <div className="home">
      <HeaderUser />
      <Sidebar />
      <div className="employeeWraper">
        <div className="headingSec">
          <h1>All Sub Admin</h1>
          <a href="/add-sub-admin" className="uploadCv">
            Add
          </a>
        </div>

        <div className="employyTable">
          <table>
            <thead className="">
              <tr className="">
              <th className="">#</th>
                <th className="">Name</th>
                <th className="">Email</th>
                <th className="">Contact number</th>
                <th className="">Role</th>
                <th className="">Status</th>
                <th className="">Manage</th>
              </tr>
            </thead>
            <tbody>
              {
                TableData && TableData?.map((obj,index)=>{
                  return (
                    <tr key={index}>
                      <td>{index+1}</td>
                      <td> {obj?.full_name}</td>
                      <td>{obj?.email}</td>
                      <td>
                      <a href={``} onClick={e=>{
                        e.preventDefault();
                      }} >{obj?.contact_number}</a>
                      </td>
                      <td>
                      {
                        obj?.role?.name
                      }
                      
                    </td>
                    <td>
                    <div class="form-check form-switch">
                        <input class="form-check-input" type="checkbox" id={obj?._id} checked={obj?.status == 1 ? true:false}/>
                        <label class="form-check-label" for={obj?._id}>{obj?.status == 1 ? "Active" : "Deactive"}</label>
                    </div>
                    </td>
                    <td>
                      {
                        obj?._id !== "5ec02bfab71fba742249d110" && (
                          <span className="d-inline-block" tabindex={obj?._id} data-toggle="tooltip" title="Edit">
                          <button className="actionBtn mr-2" onClick={e=>history.push(`/edit-sub-admin?user_id=${obj?._id}`)} >
                            <span class="lnr lnr-pencil "></span>
                          </button>
                          </span>
                        )
                      }
                      {
                        obj?._id !== "5ec02bfab71fba742249d110" && (
                          <span className="d-inline-block" tabindex={obj?._id} data-toggle="tooltip" title="Delete">
                          <button className="actionBtn" onClick={e=>DeleteProject(obj?._id)} >
                          <span class="lnr lnr-trash"></span>
                        </button>
                        </span>
                        )
                      }
                      
                    </td>
                  </tr>
                  )
                })
              }
              <tr>
                <td className="blankTd"> </td>
              </tr>
            </tbody>
          </table>

          {/* <div className="listingHeader">
           
          </div>
          <div className="tableBody">
            <div className="historyListing">
            
            </div>
            
          </div> */}
        </div>
      </div>
    </div>
  );
}

export default AllSubAdmin;
