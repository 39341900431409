import React, { useState, useEffect } from "react";
import HeaderUser from "../components/common/HeaderUser.component";
import Sidebar from "../components/common/Sidebar.component";
import { toast } from "react-toastify";
import makeTheApiCall from "../Api/axiosConfig";
import apiPath from "../Api/apiPath";
import { useHistory } from "react-router-dom";

function AddProjects() {
  const history = useHistory();
  const [ListOfClient, setListOfClient] = useState(null);
  const [InputFields, setInputFields] = useState({
    start_date: new Date(),
  });

  const HandleInputsFields = (name,value) => {
    setInputFields((prev)=>({
      ...prev,
      [name]: value,
    }))
  };

  const SubmitForm = (e) => {
    e.preventDefault();
    if (!InputFields.name) {
      toast.error("Project name required!");
      return;
    }
    let apiOptions = {
      url: apiPath.Create_Project,
      data: InputFields,
      method: "post",
    };
    makeTheApiCall(apiOptions).then((response) => {
      let responseObj = response.success ? response?.success?.data : null;
      if(responseObj){
        toast.success('Project has been added successfully.');
        history.replace('/projects')
      }else{
        toast.error('Please try again later.')
      }
    });
  };

  useEffect(() => {
    let apiOptions = {
      url: apiPath.User_DropDown,
      method: "get",
    };
    makeTheApiCall(apiOptions).then((response) => {
      let responseObj = response.success ? response?.success?.data : null;
      if (responseObj) {
        setListOfClient(responseObj);
      }
    });
  }, []);

  return (
    <div className="home">
      <HeaderUser />
      <Sidebar />
      <div className="employeeWraper">
        <div className="headingSec mB30">
          <h1>Project</h1>
        </div>
        <div className="profileTabs">
          <div className="profileInfo">
            <h3>Add Project</h3>
            <div id="editInfo" class="accordion-body collapse show">
              <div class="accordion-inner">
                <div className="row">
                  <div className="col-md-12">
                    <form className="dc-formtheme  do-login-form">
                      <fieldset>
                        <div className="row">
                          <div className="col-md-6 col-lg-4">
                            <div className="form-group">
                              <label>Project Name</label>
                              <input
                                type="text"
                                name="name"
                                className="form-control"
                                placeholder="Test Project"
                                value={InputFields.name}
                                onChange={(e)=> HandleInputsFields("name",e.target.value)}
                              />
                            </div>
                          </div>
                          <div className="col-md-6 col-lg-4">
                            <div className="form-group">
                              <label>Assigned Client</label>
                              <select
                                name="assigned_user"
                                className="form-control"
                                placeholder="select client"
                                value={InputFields.assigned_user}
                                onChange={(e) => HandleInputsFields("assigned_user",e.target.value)}
                              >
                                <option value="">Select a client</option>
                                {
                                  ListOfClient && ListOfClient?.map(client=>{
                                    return (
                                      <option value={client._id}>{client?.full_name}</option>
                                    )
                                  })
                                }
                              </select>
                            </div>
                          </div>
                        </div>
                        {/* <ul className="dc-accountinfo deactiveAccount">
                          <li>
                              <span className="pr-2">Active</span>
                            <div className="dc-on-off pull-left">
                              <input
                                type="checkbox"
                                id="hide-on"
                                name="contact_form"
                              />
                              <label for="hide-on">
                                <i></i>
                              </label>
                            </div>
                            <span>Deactivate</span>
                          </li>
                          </ul> */}
                      </fieldset>
                    </form>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <div className="col-md-12">
                      <div className="btnsGroup">
                        <button
                          className="dc-btn cancelBtn"
                          onClick={(e) => history.push("/projects")}
                        >
                          Cancel
                        </button>
                        <button
                          className="dc-btn dc-btn bookAppo dc-btn-primary uploadCv"
                          onClick={(e) => SubmitForm(e)}
                        >
                          Add/Save Project
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddProjects;
