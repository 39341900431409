import React, { useState, useEffect } from "react";
import HeaderUser from "../components/common/HeaderUser.component";
import Sidebar from "../components/common/Sidebar.component";
import makeTheApiCall from "../Api/axiosConfig";
import apiPath from "../Api/apiPath";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";

function Settings() {
  const history = useHistory();
  const [InputFields, setInputFields] = useState({});

  const HandleInputsFields = (name, value) => {
    setInputFields((prev) => ({
      ...prev,
      [name]: value,
    }));
  };
  const SubmitForm = (e) => {
    e.preventDefault();
    if (!InputFields.full_name) {
      toast.error("Name required!");
      return;
    }
    // if (!InputFields.role) {
    //   toast.error("Role required!");
    //   return;
    // }
    if (!InputFields.email) {
      toast.error("Email required!");
      return;
    }
    if (!InputFields.contact_number) {
      toast.error("Mobile required!");
      return;
    }
    // if (!InputFields.password) {
    //   toast.error("Password required!");
    //   return;
    // }
    let apiOptions = {
      url: apiPath.Edit_User,
      data: InputFields,
      method: "post",
    };
    makeTheApiCall(apiOptions).then((response) => {
      let responseObj = response.success ? response?.success?.data : null;
      // console.log(responseObj);
      if (responseObj) {
        toast.success("Settings updated.");
        history.replace("/dashboard");
      }
    });
  };

  useEffect(() => {
    let apiOptions = {
      url: apiPath.Logged_User_Details,
      method: "get",
      params: {},
    };
    makeTheApiCall(apiOptions).then((response) => {
      let responseObj = response.success ? response?.success?.data : null;
      if (responseObj) {
        let apiOptions2 = {
          url: apiPath.User_Details,
          method: "get",
          params: {
            email:responseObj?.email
          },
        };
        makeTheApiCall(apiOptions2).then((response) => {
          let responseObj2 = response.success ? response?.success?.data : null;
          if(responseObj2){
            setInputFields({
              id:responseObj2?._id,
              full_name:responseObj2?.full_name,
              email:responseObj2?.email,
              contact_number:responseObj2?.contact_number
          })
          }
        });
      }
    });
  }, []);
  return (
    <div className="home">
      <HeaderUser />
      <Sidebar />
      <div className="employeeWraper editProfilePage">
        <div className="headingSec mB30">
          <h1>Settings</h1>
        </div>
        <div className="row mB50 justify-content-lg-center">
          <div className="col-md-12">
            <div className="profileNote">
              <span className="iconsProfile">
                <span class="lnr lnr-user"></span>
              </span>
              <div className="noteTitle">Edit Personal Info</div>
            </div>
          </div>
          <div className="col-md-12 mT45">
            <div className="profileInfo">
              <div class="accordion-heading">
                <a
                  class="accordion-toggle"
                  data-toggle="collapse"
                  data-parent="#editProfile"
                  href="#editInfo"
                >
                  Edit Personal Info
                </a>
              </div>
              <div id="editInfo" class="accordion-body collapse show">
                <div class="accordion-inner">
                  <div className="row">
                    <div className="col-md-8 orderM-2">
                      <form className="dc-formtheme  do-login-form">
                        <fieldset>
                          <div className="row">
                            <div className="col-md-6">
                              <div className="form-group">
                                <label>First Name</label>
                                <input
                                type="text"
                                name="full_name"
                                className="form-control"
                                placeholder="Geroge Martin"
                                value={InputFields?.full_name}
                                onChange={e=>HandleInputsFields('full_name',e.target.value)}
                              />
                              </div>
                            </div>

                            <div className="col-md-6">
                              <div className="form-group">
                                <label>Email</label>
                                <input
                                type="email"
                                name="email"
                                value={InputFields?.email}
                                className="form-control"
                                placeholder="George.smith@mymail.com"
                                onChange={e=>HandleInputsFields('email',e.target.value)}
                              />
                              </div>
                            </div>

                            <div className="col-md-6">
                              <div className="form-group">
                                <label>Mobile Number</label>
                                <input
                                type="text"
                                name="contact_number"
                                className="form-control"
                                placeholder="enter mobile number"
                                value={InputFields?.contact_number}
                                onChange={e=>HandleInputsFields('contact_number',e.target.value)}
                              />
                              </div>
                            </div>
                          </div>
                        </fieldset>
                      </form>
                    </div>
                    <div className="col-md-4 orderM-1">
                      <div className="uploadProfile">
                        <div className="custom-file">
                          <input
                            type="file"
                            className="custom-file-input"
                            id="customFileLangHTML"
                          />
                          <label
                            className="custom-file-label"
                            for="customFileLangHTML"
                            data-browse="Bestand kiezen"
                          ></label>
                        </div>
                        <div className="imageText">
                          {" "}
                          <span>*Max Size Limit</span>
                          1024X1024 px / 1024 kb
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12">
                      <button className="dc-btn dc-btn bookAppo dc-btn-primary uploadCv" onClick={e=>SubmitForm(e)}>
                        Save Changes
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row mB50 justify-content-lg-center ">
          <div className="col-md-12">
            <div className="profileNote">
              <span className="iconsProfile">
                <span class="lnr lnr-warning"></span>
              </span>
              <div className="noteTitle">Change Password</div>
            </div>
          </div>
          <div className="col-md-12 mT45">
            <div className="profileInfo">
              <div className="row">
                <div className="col-md-12 ">
                  <form className="dc-formtheme  do-login-form">
                    <fieldset>
                      <div class="accordion-heading">
                        <a
                          class="accordion-toggle"
                          data-toggle="collapse"
                          data-parent="#accordion2"
                          href="#collapseOne"
                        >
                          Change Password
                        </a>
                      </div>
                      <div id="collapseOne" class="accordion-body collapse">
                        <div class="accordion-inner">
                          <div className="row">
                            <div className="col-md-4">
                              <div className="form-group">
                                <label>Old Password</label>
                                <input
                                  type="Password"
                                  name="Password"
                                  className="form-control"
                                  placeholder=""
                                />
                              </div>
                            </div>
                            <div className="col-md-4">
                              <div className="form-group">
                                <label>New Password</label>
                                <input
                                  type="Password"
                                  name="username"
                                  className="form-control"
                                  placeholder="XXXXXX"
                                />
                              </div>
                            </div>
                            <div className="col-md-4">
                              <div className="form-group">
                                <label>Confirm New Password</label>
                                <input
                                  type="Password"
                                  name="username"
                                  className="form-control"
                                  placeholder="XXXXXX"
                                />
                              </div>
                            </div>
                          </div>

                          <div className="row">
                            <div className="col-md-12">
                              <button className="dc-btn dc-btn bookAppo dc-btn-primary uploadCv">
                                Update Password
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </fieldset>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Settings;
