import React,{useState} from "react";
import "../components/common/PopularSpecialties.component";
import Webcam from "react-webcam";
import { useHistory } from "react-router-dom";
import makeTheApiCall from '../Api/axiosConfig'
import apiPath from '../Api/apiPath';
import {toast} from 'react-toastify';
import {Buffer} from 'buffer';
const videoConstraints = {
  width: 620,
  height: 430,
  facingMode: "user",
};


function Customer() {
  const history = useHistory();
  let applicantId = history.location.search?.split('applicant=');
  const [userId, setUserId] = React.useState(applicantId ? applicantId[1]:null);

  const [ApplicantData, setApplicantData] = React.useState(null);
  const [isValidate,setIsValidate] = React.useState(false);
  const [dob, setDob] = React.useState(null);
  const [imageCapture,setImageCapture] = React.useState(false);
  const [startUploading,setStartUploading] = React.useState(false)
  const [imgeSr, setImageSrc] = React.useState(null);

  const webcamRef = React.useRef(null);

  const [LabelText,setLabelText] = React.useState('Capture Image')
  const [isDisabled ,setDisabled]= React.useState(false);
  const [url ,setUrl]= React.useState(null);
  const [IsVideoCapture, setIsCapture]=React.useState(false);

 


  function UploadToAwsBucket(file,userId){
    setDisabled(true);
    //upload capture image to s3 using api
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(async function(pos) {
        let location ={
          latitude: pos.coords.latitude,
          longitude: pos.coords.longitude
        }
        let apiOptions = {
          url :apiPath.Upload_to_s3,
          data :{
          buffer:file,applicant_id:userId,location:location
          },
          method:"post"
        };
        makeTheApiCall(apiOptions).then((response)=>{
          setDisabled(false);
          let responseObj = response.success ? response?.success?.data : null;
          if(responseObj){
            // setUrl(response?.success?.url);
            // setLabelText('Re-take image');
            // setImageCapture(true)
            uploadVideoToS3("",'test-video');
            // window.location.href="/customer-verify-success"
            /** Changes setImageCapture comment to redirect to next page */
          }else{
            alert('Please retry to capture image.')
          }
        })
      })
    }else{
      let apiOptions = {
        url :apiPath.Upload_to_s3,
        data :{
        buffer:file,applicant_id:userId,location:{}
        },
        method:"post"
      };
      makeTheApiCall(apiOptions).then((response)=>{
        setDisabled(false);
        let responseObj = response.success ? response?.success?.data : null;
        if(responseObj){
          // setUrl(response?.success?.url);
          // setLabelText('Re-take image');
          // setImageCapture(true)
          // window.location.href="/customer-verify-success"
          uploadVideoToS3("",'test-video');
        }else{
          
          alert('Please retry to capture image.')
        }
      })
    }
  };

  function uploadVideoToS3(blob,name){
    
    let apiOptions = {
      url :apiPath.Upload_Video_to_s3,
      data :{
        // blob:blob,
        applicant_id:userId
      },
      method:"post"
    };
    makeTheApiCall(apiOptions).then((response)=>{
      let responseObj = response.success ? response?.success?.data : null;
      if(responseObj){
        window.location.href="/customer-verify-success"
      }else{
        toast.warning('Please retry to capture image or refresh the window');
        
      }
    })
  }

  const capture = React.useCallback(() => {
  
    const imageSrc = webcamRef.current.getScreenshot();
    setImageSrc(imageSrc);
    setImageCapture(true);
    setUrl(imageSrc);
    setLabelText('Re-take image');
    // UploadToAwsBucket(imageSrc,userId);
  }, [webcamRef]);

  // Video record variable and function
  const mediaRecorderRef = React.useRef(null);
  const [capturing, setCapturing] = React.useState(false);
  const [recordedChunks, setRecordedChunks] = React.useState([]);

  //Capture video method
  const handleStartCaptureClick = React.useCallback(() => {
    mediaRecorderRef.current = new MediaRecorder(webcamRef.current.stream, {
      mimeType: "video/webm",
    });
    mediaRecorderRef.current.addEventListener(
      "dataavailable",
      handleDataAvailable
    );
    mediaRecorderRef.current.start();

    setTimeout(() => {
      handleStopCaptureClick();
    }, 10000);
  }, [webcamRef,mediaRecorderRef]);

  const handleDataAvailable = React.useCallback(
    ({ data }) => {
      if (data.size > 0) {
        setRecordedChunks((prev) => prev.concat(data));
      }
    },
    [setRecordedChunks]
  );

  const handleStopCaptureClick = React.useCallback(() => {
    mediaRecorderRef.current.stop();
    setIsCapture(true);
  }, [mediaRecorderRef, webcamRef]);

  const uploadVideo = ()=>{
    UploadToAwsBucket(imgeSr,userId)
    
  };

  //get applicant data
  React.useEffect(()=>{
    if(userId){
      let apiOptions = {
        url :apiPath.GET_Applicant_Public,
        params :{
         id:userId
        },
        method:"get"
      };
      makeTheApiCall(apiOptions).then((response)=>{
        let responseObj = response.success ? response?.success?.data?.result : null;
       
        if(responseObj && responseObj.is_completed === 0){
          setApplicantData(responseObj);
          if(responseObj && (responseObj.step_completed === 1)){
            // setIsValidate(true);
          }
          if(responseObj && responseObj?.step_completed === 2){
            // setIsValidate(true);
            // setImageCapture(true);
            // setLabelText('Re-take Image')
          }
        }
        if(responseObj && responseObj.is_completed === 1){
          window.location.href="/customer-verify-success"
        }
        if(userId && !responseObj){
          alert('Project has been archived. Please contact your administrator.');
          window.location.href="/"
        }
      });
    }
  },[userId]);

  const [applicationNumber, setApplicationNumber] = useState("");
  //Validate applicants
  const ValidateApplicant=()=>{
    
    if(ApplicantData?.applicant_name && applicationNumber && dob){
      let apiOptions = {
        url :apiPath.Applicant_OTP_Validate,
        data :{
         id:userId,
         application_number:applicationNumber,
         otp:dob
        },
        method:"post"
      };
      makeTheApiCall(apiOptions).then((response)=>{
        let responseData = response?.success?.data;
        if(responseData){
          setIsValidate(true);
        }else{
          toast.error('Validation failed.Please re-try to validate.')
        }
      });
    }else{
        toast.info('Please provide application number and OTP value.') 
    }
  }

  const [IsOtpSend, setIsOtpSend] = useState(false);
  const [ResendOtpEnabled, setResendOtpEnabled] = useState(false);
  const [ResendDisabled, setResendDisabled] = useState(true);
  const [Timer,setTimer] = React.useState(120);
  const startTimerFunc= React.useCallback(()=>{
    let interval = setInterval(() =>{setTimer((timer)=>timer-1);}, 1000);
    setTimeout(() => {
      clearInterval(interval);
      setTimer(null);
      setResendDisabled(false)
    }, 120000);
  },[]);

  const generateOTP=()=>{

    if(ApplicantData?.applicant_name && applicationNumber){
        setResendDisabled(true)
        //**Call Generate OTP API */
        let apiOptions = {
            url :apiPath.Applicant_OTP_GENERATE,
            data :{
             id:userId,
             application_number:applicationNumber
            },
            method:"POST"
          };
          makeTheApiCall(apiOptions).then((response)=>{
            let responseData = response?.success?.data;
            if(responseData){
              toast.success("OTP has been send to registered mobile number.");
              setIsOtpSend(true);
              setResendOtpEnabled(true);
              setTimer(120)
              startTimerFunc()
            }else{
              toast.error('Application Number Does Not Match - गलत एप्लीकेशन नंबर')
            }
          });
    }else{
        toast.info('Please provide application number.') 
    }
  };

  return (
    <div className="home">
      <body class="form-v5">
        <div class="page-content">
          <div
            class="form-v5-content customerOuter"
            style={{ marginTop: "20px" }}
          >
            <div class="tab-content" id="myTabContent">
              <div
                class="tab-pane fade show active"
                id="home"
                role="tabpanel"
                aria-labelledby="home-tab"
              >
                {
                  isValidate  ? (
                  <div style={{position:"relative",background:"none"}}>
                    <Webcam
                    audio={false}
                    ref={webcamRef}
                    screenshotFormat="image/jpeg"
                    videoConstraints={videoConstraints}
                  />
                    <div className="text-center p-2" style={{backgroundColor:"#ffff"}}>
                    {
                      imageCapture ? <button
                      onClick={e=>window.location.reload()}
                      className="btn btn-primary"
                      >
                      Re-take
                      </button> :
                      <button
                      onClick={capture}
                      className="btn btn-primary"
                      disabled={isDisabled}
                      
                      >
                      {LabelText}
                      </button>
                    }
                        
                        {
                          url && (
                          <button
                            // onClick={capture}
                            className="btn btn-primary ml-2"
                            data-toggle="modal" data-target="#exampleModal"
                          >
                            Preview Image 
                          </button>
                          )
                        }
                    </div>
                 
                  <div className="form-group">
                    <div className="btnsGroup left">  
                        

            {
              imageCapture && (
                <button
                onClick={uploadVideo}
                  className="dc-btn dc-btn bookAppo  uploadCv mr-3 btn-primary"
                >
                Submit
                </button>
              )
            }
            
                    </div>
                  </div>
              </div>) : 
                 <form>
                 <div className="form-group">
                   <label>Name</label>
                   <input
                     type="text"
                     name="applicant_name"
                     value={ApplicantData?.applicant_name}
                     className="form-control"
                     placeholder="Applicant Name"
                     readOnly
                   />
                 </div>

                 <div className="form-group">
                   <label>Fathers Name</label>
                   <input
                     type="text"
                     name="username"
                     value={ApplicantData?.father_name}
                     className="form-control"
                     placeholder="Father Name"
                     readOnly
                   />
                 </div>

                 <div className="form-group">
                   <label>Application Number</label>
                   <input
                     type="text"
                     name="Enter your application Number *"
                     value={applicationNumber}
                     className="form-control"
                     placeholder="Enter your applicant Number *"
                     onChange={e=>setApplicationNumber(e.target.value)}
                   />
                   <label style={{fontSize:"14px",color:"#007bff"}}>Provide Application number to generate OTP.</label>
                 </div>
                 {
                  IsOtpSend && (
                    <div className="form-group">
                      <label>OTP</label>
                      <input
                        type="number"
                        name="OTP"
                        className="form-control"
                        placeholder="Please provide 4 digit OTP."
                        onChange={e=>setDob(e.target.value)}
                      />
                      {
                        Timer && (
                            <label>Resend OTP in : {Timer}</label>
                        )
                      }
                    </div>
                  )
                 }

                 <div className="form-group">
                   <div className="btnsGroup left">
                    {
                       ResendOtpEnabled ? (
                        <button className="btn btn-primary" disabled={ResendDisabled} onClick={e=>{e.preventDefault();generateOTP()}}>
                            Re-Send OTP
                        </button>
                       ) :(
                            <button className="btn btn-primary" disabled={applicationNumber != "" ? false:true } onClick={e=>{e.preventDefault();generateOTP();}}>
                            Generate OTP
                            </button>
                       )
                    }

                    {
                      IsOtpSend && (
                        <button className="btn btn-primary ml-3" onClick={e=>{e.preventDefault();ValidateApplicant()}}>
                          Validate
                        </button>
                      )
                    }
                   </div>
                 </div>
               </form>
             
                }
                </div>
            </div>
          </div>

          {/* modal */}
          <div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog" role="document">
              <div class="modal-content">
                <div class="modal-header">
                  <h5 class="modal-title" id="exampleModalLabel">Prev Image</h5>
                  <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div class="modal-body">
                    <img src={url} alt="prev" />
                </div>
                <div class="modal-footer">
                  <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                </div>
              </div>
            </div>
          </div>
          <div className="clearfix"></div>
        </div>
      </body>
    </div>
  );
}

export default Customer;
