import React, { useState, useEffect } from "react";
import HeaderUser from "../components/common/HeaderUser.component";
import Sidebar from "../components/common/Sidebar.component";
import { toast } from "react-toastify";
import makeTheApiCall from "../Api/axiosConfig";
import apiPath from "../Api/apiPath";
import { useHistory } from "react-router-dom";

function AddRoles() {
  const history = useHistory();
  const [ListOfClient, setListOfClient] = useState(null);
  const [InputFields, setInputFields] = useState({
    start_date: new Date(),
  });

  const HandleInputsFields = (name,value) => {
    setInputFields((prev)=>({
      ...prev,
      [name]: value,
    }))
  };

  const SubmitForm = (e) => {
    e.preventDefault();
    if (!InputFields.name) {
      toast.error("Name required!");
      return;
    }
    let apiOptions = {
      url: apiPath.Create_Role,
      data: InputFields,
      method: "post",
    };
    makeTheApiCall(apiOptions).then((response) => {
      let responseObj = response.success ? response?.success?.data : null;
      if(responseObj){
        toast.success('Role has been added successfully.');
        history.replace('/role')
      }else{
        toast.error('Please try again later.')
      }
    });
  };

  useEffect(() => {
    let apiOptions = {
      url: apiPath.User_DropDown,
      method: "get",
    };
    makeTheApiCall(apiOptions).then((response) => {
      let responseObj = response.success ? response?.success?.data : null;
      if (responseObj) {
        setListOfClient(responseObj);
      }
    });
  }, []);

  return (
    <div className="home">
      <HeaderUser />
      <Sidebar />
      <div className="employeeWraper">
        <div className="headingSec mB30">
          <h1>Roles</h1>
        </div>
        <div className="profileTabs">
          <div className="profileInfo">
            <h3>Add Role</h3>
            <div id="editInfo" class="accordion-body collapse show">
              <div class="accordion-inner">
                <div className="row">
                  <div className="col-md-12">
                    <form className="dc-formtheme  do-login-form">
                      <fieldset>
                        <div className="row">
                          <div className="col-md-6 col-lg-4">
                            <div className="form-group">
                              <label>Name</label>
                              <input
                                type="text"
                                name="name"
                                className="form-control"
                                placeholder="client"
                                value={InputFields.name}
                                onChange={(e)=> HandleInputsFields("name",e.target.value)}
                              />
                            </div>
                          </div>
                        </div>
                      </fieldset>
                    </form>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <div className="col-md-12">
                      <div className="btnsGroup">
                        <button
                          className="dc-btn cancelBtn"
                          onClick={(e) => history.push("/role")}
                        >
                          Cancel
                        </button>
                        <button
                          className="dc-btn dc-btn bookAppo dc-btn-primary uploadCv"
                          onClick={(e) => SubmitForm(e)}
                        >
                          Save
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddRoles;
