import React, {useState,useEffect} from "react";
import HeaderUser from "../components/common/HeaderUser.component";
import Sidebar from "../components/common/Sidebar.component";
import makeTheApiCall from '../Api/axiosConfig'
import apiPath from '../Api/apiPath';
import {toast} from 'react-toastify'
import {useHistory} from 'react-router-dom'

function CustomerTrack() {
  const history= useHistory();
  const [TableData, setTableData] = useState(null);
  let projectId = history.location.search?.split('project_id=');
  const [AssignedId, setAssignedId] = useState(projectId ? projectId[1]:null);
  const [ProjectList, setProjectList] = useState(null);
  const [ApplicantId, setApplicantId] = useState(null);

  const NotifyApplicant = (id)=>{
    let response = window.confirm('Are you sure you want to notify applicant ?');
    if(response){
      let apiOptions = {
        url :apiPath.Notify_Applicant,
        params :{
          id:id
        },
        method:"get"
      };
      makeTheApiCall(apiOptions).then((response)=>{
        let responseObj = response.success ? response?.success?.message : null;
        if(responseObj){
          toast.success(responseObj);
          // setTimeout(() => {
          //   window.location.reload();
          // }, 2000);
        }else{
          toast.error('Please try again later');
        }
      });
    }
  };
  
  useEffect(() => {
    let apiOptions = {
      url :apiPath.Get_Applicant_List,
      data :{
        page: 1,
        limit: 1000,
        project_id:AssignedId
      },
      method:"post"
    };
    makeTheApiCall(apiOptions).then((response)=>{
      let responseObj = response.success ? response?.success?.data : null;
      if(responseObj){
        setTableData(responseObj)
      }
    });
  }, [AssignedId]);
  useEffect(() => {
    let apiOptions = {
      url :apiPath.Project_DropDown,
      data :{
        page: 1,
        limit: 1000,
      },
      method:"post"
    };
    makeTheApiCall(apiOptions).then((response)=>{
      let responseObj = response.success ? response?.success?.data : null;
      if(responseObj){
        setProjectList(responseObj)
      }
    });
  }, []);
  const [ApplicantDetails, setApplicantDetails] = useState(null);
  useEffect(() => {
    if(ApplicantId){
      setApplicantDetails({})
      let apiOptions = {
        url :apiPath.Get_Applicant_Details,
        params :{
          id:ApplicantId
        },
        method:"get"
      };
      makeTheApiCall(apiOptions).then((response)=>{
        let responseObj = response.success ? response?.success?.data : null;
        
        if(responseObj){
          setApplicantDetails(responseObj)
        }
      });
    }
  }, [ApplicantId]);

  
  return (
    <div className="home">
      <HeaderUser />
      <Sidebar />
      <div className="employeeWraper">
        <div className="headingSec">
          <h1>Customer Track List</h1>
        </div>
        <div className="headingSec">
        <select name="project" className="left" value={AssignedId} onChange={e=>setAssignedId(e.target.value)}>
            <option value="">Select Project</option>
            {
              ProjectList && ProjectList?.map((list,key)=>{
                return (
                  <option value={list._id}>{list?.name}</option>
                )
              })
            }
          </select>
        </div>

        <div className="employyTable">
          <table>
            <thead className="">
              <tr className="">
              <th className="">#</th>
                <th className="">Name</th>
                <th className="">Reg Number</th>
                <th className="">Verified status</th>
                <th className="">Track Status</th>
                <th className="">Match %</th>
                <th className="">Sms Send</th>
                <th className="">Email Send</th>
                <th className=""> Manage </th>
              </tr>
            </thead>
            <tbody>
            {
                TableData && TableData?.map((obj,index)=>{
                  return (
                    <tr>
                      <td>{index+1}</td>
                      <td>{obj?.applicant_name}</td>
                      <td>{obj?.registration_number}</td>
                      <td>{obj?.is_completed === 0 ? 'Waiting' : 'Completed'}</td>
                      <td>{obj?.step_completed === 0 ? 'Step 0' : (obj?.step_completed === 1 ? 'Step 1' : (obj?.step_completed === 2 ? 'Step 2' : 'Step3'))}</td>
                      <td>{obj?.match_count?.FaceMatches?.length>0 ? obj?.match_count?.FaceMatches[0].Similarity : '0'}  </td>
                      <td>{obj?.sms_send === 0 ? "No" : 'Yes'}</td>
                      <td>{obj?.email_send === 0 ?'No': 'Yes'}</td>
                      <td>
                      <span className="d-inline-block" tabindex={obj?._id} data-toggle="tooltip" title="send sms/email">
                      <button className="actionBtn mr-2" onClick={e=>NotifyApplicant(obj?._id)}>
                        <span class="lnr lnr-envelope"></span>
                      </button>
                      </span>
                      <span className="d-inline-block" tabindex={obj?._id} data-toggle="tooltip" title="View">
                      <button className="actionBtn mr-2" onClick={e=>setApplicantId(obj._id)} >
                        <span class="lnr lnr-eye " data-toggle="modal" data-target="#exampleModal"></span>
                      </button>
                      </span>
                      </td>
                    </tr>
                  )
                })
              }
              <tr>
                <td className="blankTd"> </td>
              </tr>
              
            </tbody>
          </table>
        </div>
      </div>

      {/* modal */}
      <div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog" role="document">
              <div class="modal-content">
                <div class="modal-header">
                  <h5 class="modal-title" id="exampleModalLabel">Applicant details</h5>
                  <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div class="modal-body">
                <div className="row">
                  <div className="col">
                  Original Image
                  </div>
                  <div className="col">
                  <img src={ApplicantDetails?.photo_url} alt="Original" height="150" width="150" />
                  </div>
                  </div>
                  <div className="row">
                  <div className="col">
                  Captured Image
                  </div>
                  <div className="col mT10">
                  <img src={ApplicantDetails?.UploadPhoto} alt="Original" height="150" width="150" />
                  </div>
                  </div>
                  <div className="row">
                  <div className="col">
                  Captured Video
                  </div>
                  <div className="col  mT10">
                    {
                      ApplicantDetails?.video_url ? (
                      <video width="320" height="240" controls>
                          <source src={ApplicantDetails?.video_url} type="video/webm" />
                        </video>) : 'N/A'
                    }
                 
                  </div>
                  </div>
                <div className="row">
                  <div className="col">
                  Applicant  Name
                  </div>
                  <div className="col">
                    {ApplicantDetails?.applicant_name}
                  </div>
                  </div>
                  <div className="row">
                  <div className="col">
                    Application Number
                  </div>
                  <div className="col">
                    {ApplicantDetails?.application_number}
                  </div>
                  </div>
                  <div className="row">
                    <div className="col">
                      Registration Number
                    </div>
                    <div className="col">
                      {ApplicantDetails?.registration_number}
                  </div>
                  </div>
                  {/* <div className="row">
                  <div className="col">
                    Roll Number
                  </div>
                  <div className="col">
                    {"value"}
                  </div>
                  </div> */}
                  <div className="row">
                  <div className="col">
                    Father Name
                  </div>
                  <div className="col">
                    {ApplicantDetails?.father_name}
                  </div>
                  </div>
                  <div className="row">
                  <div className="col">
                    Mother Name
                  </div>
                  <div className="col">
                    {ApplicantDetails?.mother_name}
                  </div>
                  </div>
                  <div className="row">
                  <div className="col">
                    Gender
                  </div>
                  <div className="col">
                    {ApplicantDetails?.gender}
                  </div>
                  </div>
                  <div className="row">
                  <div className="col">
                    Marital Status
                  </div>
                  <div className="col">
                    {ApplicantDetails?.marital_status}
                  </div>
                  </div>
                  <div className="row">
                  <div className="col">
                    Date of birth
                  </div>
                  <div className="col">
                    {ApplicantDetails?.dob}
                  </div>
                  </div>
                  <div className="row">
                  <div className="col">
                    Category
                  </div>
                  <div className="col">
                    {ApplicantDetails?.category}
                  </div>
                  </div>
                  <div className="row">
                  <div className="col">
                    Religion
                  </div>
                  <div className="col">
                    {ApplicantDetails?.religion}
                  </div>
                  </div>
                  <div className="row">
                  <div className="col">
                    Residents of Rajasthan
                  </div>
                  <div className="col">
                    {ApplicantDetails?.resident_of_rajasthan}
                  </div>
                  </div>
                  <div className="row">
                  <div className="col">
                    Home District
                  </div>
                  <div className="col">
                    {ApplicantDetails?.home_district}
                  </div>
                  </div>
                  <div className="row">
                  <div className="col">
                    Home State
                  </div>
                  <div className="col">
                    {ApplicantDetails?.home_state}
                  </div>
                  </div>
                  <div className="row">
                  <div className="col">
                    Nationality
                  </div>
                  <div className="col">
                    {ApplicantDetails?.nationality}
                  </div>
                  </div>
                  <div className="row">
                  <div className="col">
                    Address
                  </div>
                  <div className="col">
                    {ApplicantDetails?.address}
                  </div>
                  </div>
                  <div className="row">
                  <div className="col">
                    City
                  </div>
                  <div className="col">
                    {ApplicantDetails?.city}
                  </div>
                  </div>
                </div>
                <div class="modal-footer">
                  <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                </div>
              </div>
            </div>
          </div>

    </div>
  );
}

export default  CustomerTrack;
