import React from "react";
const Neuro = "/assets/images/specialization_icons/neurosurgeon.svg";
const Physician = "/assets/images/specialization_icons/physician.svg";
const Orthopedic = "/assets/images/specialization_icons/orthopedic.svg";
const ChildSpecialist =
  "/assets/images/specialization_icons/childSpecialist.svg";
const AllergySpecialist =
  "/assets/images/specialization_icons/allergySpecialist.svg";
const Dietitian = "/assets/images/specialization_icons/dietitian.svg";

function DocSpecializationCard() {
  return (
    <div className="dc-haslayout">
      <div className="container-fluid">
        <div className="row">
          {/* <div className="d-flex align-items-center justify-content-center"> */}
          <div id="dc-doctorspecholder">
            <div className="item dc-doctordetails-holder dc-titlecolor1">
              <span className="dc-slidercounter">&nbsp;</span>
              <h3 className="text-center">
                <img src={Neuro} alt="Neurosurgeon" />
                Neurosurgeon
              </h3>
              <a href="#!;" className="dc-btn dc-btnactive">
                Show Nearest
              </a>
            </div>
            <div className="item dc-doctordetails-holder dc-titlecolor2">
              <span className="dc-slidercounter">&nbsp;</span>
              <h3 className="text-center">
                <img src={Physician} alt="Physician" />
                Physician
              </h3>
              <a href="#!;" className="dc-btn dc-btnactive">
                Show Nearest
              </a>
            </div>
            <div className="item dc-doctordetails-holder dc-titlecolor3">
              <span className="dc-slidercounter">&nbsp;</span>
              <h3 className="text-center">
                <img src={Orthopedic} alt="Orthopedic" />
                Orthopedic
              </h3>
              <a href="#!;" className="dc-btn dc-btnactive">
                Show Nearest
              </a>
            </div>
            <div className="item dc-doctordetails-holder dc-titlecolor4">
              <span className="dc-slidercounter">&nbsp;</span>
              <h3 className="text-center">
                <img src={ChildSpecialist} alt="Child Specialist" />
                Child Specialist
              </h3>
              <a href="#!;" className="dc-btn dc-btnactive">
                Show Nearest
              </a>
            </div>
            <div className="item dc-doctordetails-holder dc-titlecolor9">
              <span className="dc-slidercounter">&nbsp;</span>
              <h3 className="text-center">
                <img src={AllergySpecialist} alt="Allergy Specialist" />
                Allergy Specialist
              </h3>
              <a href="#!;" className="dc-btn dc-btnactive">
                Show Nearest
              </a>
            </div>
            <div className="item dc-doctordetails-holder dc-titlecolor5">
              <span className="dc-slidercounter">&nbsp;</span>
              <h3 className="text-center">
                <img src={Dietitian} alt="Dietitian" />
                Dietitian
              </h3>
              <a href="#!;" className="dc-btn dc-btnactive">
                Show Nearest
              </a>
            </div>
            {/* </div> */}
          </div>
        </div>
      </div>
    </div>
  );
}

export default DocSpecializationCard;
