import React from "react";

function LoginModal() {
  // let search = window.location.search;
  // let params = new URLSearchParams(search);
  // let foo = params.get("id");
  return (
    <div>
      <div className="modal fade" id="searchModal">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header border-0">
              <div className="searchInput">
                <button className="searchIcon">
                  <span className="lnr lnr-magnifier"></span>
                </button>
                <input
                  type="search"
                  placeholder="Search"
                  className="form-control"
                />
                <button type="button" className="close" data-dismiss="modal">
                  <span className="lnr lnr-cross-circle"></span>
                </button>
              </div>
            </div>

            <div className="modal-body text-center w-100">
              <div className="dc-advancedsearch-container">
                <div className="dc-advancedsearch clearfix">
                  <form className="dc-formtheme dc-form-advancedsearch d-flex clearfix">
                    <div className="form-group">
                      <label>
                        Name
                        <input
                          type="text"
                          name="search"
                          className="form-control"
                          placeholder="Search doctors, clinics, hospitals, etc."
                        />
                      </label>
                    </div>
                    <div className="form-group">
                      <label>
                        Date
                        <input
                          type="date"
                          name="appointment"
                          className="form-control"
                          placeholder="Appointment"
                        />
                      </label>
                    </div>
                    <div className="form-group">
                      <label>
                        Location
                        <div className="">
                          {/* dc-select class is removed so that white space does not appear */}
                          <select
                            className="chosen-select locations"
                            data-placeholder="Country"
                            name="locations"
                            style={{ display: "none" }}
                          >
                            <option value="Location">Location*</option>
                            <option value="United State">United State</option>
                            <option value="Canada">Canada</option>
                            <option value="England">England</option>
                            <option value="Switzerland">Switzerland</option>
                            <option value="New Zealand">New Zealand</option>
                          </select>
                        </div>
                      </label>
                    </div>

                    <div className="form-group">
                      <label>
                        Insurance
                        <div className="">
                          {/* dc-select class is removed so that white space does not appear */}

                          <select
                            className="chosen-select locations insurance"
                            data-placeholder="Insurance"
                            name="insurance"
                            style={{ display: "none" }}
                          >
                            <option value="Insrance">Insurance*</option>
                            <option value="HDFC">HDFC</option>
                            <option value="Canada">Canada</option>
                            <option value="England">England</option>
                            <option value="Switzerland">Switzerland</option>
                            <option value="New Zealand">New Zealand</option>
                          </select>
                        </div>
                      </label>
                    </div>

                    <a href="/search" className="searchBtn">
                      <span className="lnr lnr-magnifier"></span>
                      <span>Search</span>
                    </a>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LoginModal;
