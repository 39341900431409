import React from "react";
import "../components/common/PopularSpecialties.component";
import Webcam from "react-webcam";
import { useHistory } from "react-router-dom";
import makeTheApiCall from '../Api/axiosConfig'
import apiPath from '../Api/apiPath';
const VideoImage = "/assets/images/video-img.jpg";

const videoConstraints = {
  width: 620,
  height: 430,
  facingMode: "user",
};

function Customer() {
  const history = useHistory();
  let applicantId = history.location.search?.split('id=');
  
  

  //get applicant data
  React.useEffect(()=>{
    if(applicantId){
      let apiOptions = {
        url :apiPath.Get_Redirect_Url,
        params :{
         id:applicantId
        },
        method:"get"
      };
      makeTheApiCall(apiOptions).then((response)=>{
        let responseObj = response.success ? response?.success?.data : null;
        if(responseObj){
            window.location.href=responseObj?.url
        }else{
            window.location.href="/"
        }
        
      });
    }
  },[applicantId]);

  return (
    <div className="home">
      <body class="form-v5">
        <div class="page-content">
          <div
            class="form-v5-content customerOuter"
            style={{ marginTop: "20px" }}
          >
            <div class="tab-content" id="myTabContent">
              Welcome , Please wait for link...
            </div>
          </div>
          <div className="clearfix"></div>
        </div>
      </body>
    </div>
  );
}

export default Customer;
