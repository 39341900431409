import React from "react";
const Logo = "/assets/images/ID_logo.png";

function Sidebar() {
  const path = window.location.pathname;
  return (
    <aside className="sideBarMenu">
    <nav className="navbar navbar-expand-lg navbar-dark fixed-top bg-transprent">
   
    <button
      className="navbar-toggler"
      type="button"
      data-toggle="collapse"
      data-target="#navbarCollapse"
      aria-controls="navbarCollapse"
      aria-expanded="false"
      aria-label="Toggle navigation"
    >
      <span className="navbar-toggler-icon"></span>
    </button>
  
    <div className="collapse navbar-collapse" id="navbarCollapse">
      <ul className="navbar-nav mr-auto sidenav" id="navAccordion">
       
        <li className="nav-item">
          <a className={`nav-link ${path=== '/dashboard' ? 'active' :''}`} href="/dashboard"><span class="ti-home"></span> Dashboard</a>
        </li>
         <li className="nav-item">
          <a
            className="nav-link nav-link-collapse"
            href="#"
            id="hasSubItems"
            data-toggle="collapse"
            data-target="#collapseSubItems3"
            aria-controls="collapseSubItems3"
            aria-expanded={`${path=== '/role' || path=== '/add-role' ? "true" :"false"}`}
          ><span class="ti-wheelchair"></span> Roles</a>
          <ul className="nav-second-level collapse" id="collapseSubItems3" data-parent="#navAccordion">
            <li className="nav-item">
              <a className="nav-link" href="/role">
                <span className="nav-link-text">All Roles</span>
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="/add-role">
                <span className="nav-link-text">Add New</span>
              </a>
            </li>
          </ul>
        </li>
        <li className="nav-item">
          <a className={`nav-link ${path=== '/sub-admin' || path=== '/add-sub-admin' ? 'active' :''}`} href="/sub-admin"><span class="ti-panel"></span>Sub Admin</a>
        </li>
        <li className="nav-item">
          <a className={`nav-link ${path=== '/clients' || path === "/edit-clients" || path==="/add-clients" ? 'active' :''}`} href="/clients"><span class="ti-user"></span>Clients</a>
        </li>
        <li className="nav-item">
          <a className={`nav-link ${path=== '/projects' ? 'active' :''}`} href="/projects"><span class="ti-package"></span> Projects</a>
        </li>
		   
        <li className="nav-item">
          <a className={`nav-link ${path=== '/customers' ? 'active' :''}`} href="/customers"><span class="ti-user"></span>Applicants</a>
        </li>
		    <li className="nav-item">
          <a className={`nav-link ${path=== '/customer-track' ? 'active' :''}`} href="/customer-track"><span class="ti-user"></span>Applicants Track</a>
        </li>
        
       
		    <li className="nav-item">
          <a className={`nav-link ${path=== '/email-template' ? 'active' :''}`} href="/email-template"><span class="ti-email"></span> Email Template</a>
        </li>
        
		    <li className="nav-item">
          <a className={`nav-link ${path=== '/sms-logs' ? 'active' :''}`} href="/sms-logs"><span class="ti-mobile"></span>SMS Logs</a>
        </li>
        <li className="nav-item">
          <a className={`nav-link ${path=== '/reports' ? 'active' :''}`} href="/reports"><span class="ti-receipt"></span> Reports</a>
        </li>

        <li className="nav-item">
          <a className={`nav-link ${path=== '/enquiry' ? 'active' :''}`} href="/enquiry"><span class="ti-agenda"></span> Enquiry</a>
        </li>
     
        <li className="nav-item">
          <a className="nav-link" href="/settings"> <span className="ti-settings"></span> Settings</a>
        </li>
      </ul>
     
    </div>
  </nav>
  </aside>
  );
}

export default Sidebar;
