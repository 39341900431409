import React ,{useState,useEffect} from "react";

import "../components/common/PopularSpecialties.component";
import HeaderUser from "../components/common/HeaderUser.component";
import Sidebar from "../components/common/Sidebar.component";
import { useHistory } from "react-router-dom";
import {toast} from 'react-toastify';
import makeTheApiCall from '../Api/axiosConfig'
import apiPath from '../Api/apiPath';


function AllCustomers(props) {
  const history = useHistory();
  let projectId = history.location.search?.split('project_id=');
  const [AssignedId, setAssignedId] = useState(projectId ? projectId[1]:null);
  const [ProjectList, setProjectList] = useState(null);
  const [ApplicantId, setApplicantId] = useState(null);

  const DeleteProject = (id)=>{
    let response = window.confirm('Are you sure you want to delete ?');
    if(response){
      let apiOptions = {
        url :apiPath.Delete_Applicant,
        data :{
          id:id
        },
        method:"post"
      };
      makeTheApiCall(apiOptions).then((response)=>{
        let responseObj = response.success ? response?.success?.data : null;
        if(responseObj){
          toast.success('Applicant has been deleted');
          setTimeout(() => {
            window.location.reload();
          }, 2000);

        }else{
          toast.error('Please try again later');
        }
      });
    }
  };
  const handleChange=(id,status)=>{
    let response = window.confirm('Are you sure you want to change status ?');
    if(response){
      let apiOptions = {
        url :apiPath.Applicant_Status,
        data :{
          id:id,
          status:status === 1 ? 0 : 1
        },
        method:"post"
      };
      makeTheApiCall(apiOptions).then((response)=>{
        let responseObj = response.success ? response?.success?.data : null;
        if(responseObj){
          toast.success('Applicant status changed');
          setTimeout(() => {
            window.location.reload();
          }, 2000);

        }else{
          toast.error('Please try again later');
        }
      });
    }
  }
  const importCSV=(event)=>{
    if(AssignedId){
      if (event.target.files[0]) {
        let applicant_file = event.target.files[0];
        if (
          applicant_file.type.split('/')[0] === 'image' ||
          applicant_file.type.split('/')[0] === 'IMAGE'
        ) {
          toast.error('Please select correct file.')
          return;
        }

        let formData = new FormData();
        formData.append('project_id',AssignedId);
        formData.append('applicant_file', applicant_file);

        //save data to db
        let apiOptions = {
          url :apiPath.Create_Applicant,
          data :formData,
          method:"post"
        };
        makeTheApiCall(apiOptions).then((response)=>{
          let responseObj = response.success ? response?.success?.data : null;
          toast.success("Imported successfully.");
          window.location.reload();
        });
      }
    }else{
      toast.error('Project id not found')
    }
  };


  const uploadImages = (event)=>{
    if(AssignedId){

      if (event.target.files) {

        let formData = new FormData();
        formData.append('project_id',AssignedId);

        let files = event.target.files;
        let fileLength = event.target.files.length;
       
        for (let index = 0; index < fileLength; index++) {
          formData.append(`pictures[]`, files[index]);
        }

        //save data to db
        let apiOptions = {
          url :apiPath.Upload_Photo,
          data :formData,
          method:"post"
        };
        makeTheApiCall(apiOptions).then((response)=>{
          let responseObj = response.success ? response?.success?.data : null;
          toast.success("uploaded successfully.");
          // window.location.reload();
        });
      }
    }else{
      toast.error('Project id not found')
    }
  }

  const [TableData, setTableData] = useState(null);
  useEffect(() => {
    let apiOptions = {
      url :apiPath.Project_DropDown,
      data :{
        page: 1,
        limit: 1000,
      },
      method:"post"
    };
    makeTheApiCall(apiOptions).then((response)=>{
      let responseObj = response.success ? response?.success?.data : null;
      if(responseObj){
        setProjectList(responseObj)
      }
    });
  }, []);
  useEffect(() => {
    let apiOptions = {
      url :apiPath.Get_Applicant_List,
      data :{
        page: 1,
        limit: 1000,
        project_id:AssignedId
      },
      method:"post"
    };
    makeTheApiCall(apiOptions).then((response)=>{
      let responseObj = response.success ? response?.success?.data : null;
      if(responseObj){
        setTableData(responseObj)
      }
    });
  }, [AssignedId]);

  const [ApplicantDetails, setApplicantDetails] = useState(null);
  useEffect(() => {
    if(ApplicantId){
      let apiOptions = {
        url :apiPath.Get_Applicant_Details,
        params :{
          id:ApplicantId
        },
        method:"get"
      };
      makeTheApiCall(apiOptions).then((response)=>{
        let responseObj = response.success ? response?.success?.data : null;
        
        if(responseObj){
          setApplicantDetails(responseObj)
        }
      });
    }
  }, [ApplicantId]);

  return (
    <div className="home">
      <HeaderUser />
      <Sidebar />
      <div className="employeeWraper">
        <div className="headingSec">
          <h1>All Applicants</h1>
          {/* {/* <a href="" className="uploadCv" onClick={e=>{e.preventDefault();importCSV()}}>
            {" "}
            {/* <img src={cvIcon} alt="" /> Upload CSV{" "}
          </a> */}
        </div>
        <div className="headingSec">
          <select name="project" value={AssignedId} onChange={e=>setAssignedId(e.target.value)}>
            <option value="">Select Project</option>
            {
              ProjectList && ProjectList?.map((list,key)=>{
                return (
                  <option value={list._id}>{list?.name}</option>
                )
              })
            }
          </select>
          <lable>
            Upload Applicant Record : 
          <input type="file" className="" size="20" onChange={e=>importCSV(e)} accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"/>
          </lable>
          <lable>
          Upload Applicant Images : 
          <input type="file" className="" size="20" onChange={e=>uploadImages(e)} multiple />
          </lable>
          {/* {/* <a href="" className="uploadCv" onClick={e=>{e.preventDefault();importCSV()}}>
            {" "}
            {/* <img src={cvIcon} alt="" /> Upload CSV{" "}
          </a> */}
        </div>

        <div className="employyTable">
          <table>
            <thead className="">
              <tr className="">
              <th className="">#</th>
                <th className="">Name</th>
                <th className="">Reg Number</th>
                <th className="">Application Number</th>
                <th className="">Project</th>
                <th className="">Status</th>
                <th className=""></th>
              </tr>
            </thead>

            <tbody>
              {
                TableData && TableData?.map((obj,index)=>{
                  return (
                    <tr>
                      <td>{index+1}</td>
                      <td>{obj?.applicant_name}</td>
                      <td>{obj?.registration_number}</td>
                      <td>{obj?.application_number}</td>
                      <td>{obj?.project_id?.name}</td>
                      <td>
                      <div class="form-check form-switch">
                        {/* <input class="form-check-input" type="checkbox" id={obj?._id} checked={obj?.status == 1 ? true:false}/> */}
                        <label class="form-check-label " for={obj?._id}>{obj?.status == 1 ? "Active" : "Deactive"}</label>
                    </div>
                      </td>
                      <td>
                      <span className="d-inline-block" tabindex={obj?._id} data-toggle="tooltip" title="Change status">
                      <button className="actionBtn mr-2" onClick={e=>handleChange(obj?._id,obj?.status)}>
                        <span class="lnr lnr-lock "></span>
                      </button>
                      </span>
                      <span className="d-inline-block" tabindex={obj?._id} data-toggle="tooltip" title="View">
                      <button className="actionBtn mr-2" onClick={e=>setApplicantId(obj._id)} >
                        <span class="lnr lnr-eye " data-toggle="modal" data-target="#exampleModal"></span>
                      </button>
                      </span>
                      <span className="d-inline-block" tabindex={obj?._id} data-toggle="tooltip" title="Delete">
                      <button className="actionBtn" onClick={() =>DeleteProject(obj?._id)} >
                        <span class="lnr lnr-trash"></span>
                      </button>
                      </span>
                      </td>
                    </tr>
                  )
                })
              }
              <tr>
                <td className="blankTd"> </td>
              </tr>
            </tbody>
          </table>

          {/* modal */}
          <div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog" role="document">
              <div class="modal-content">
                <div class="modal-header">
                  <h5 class="modal-title" id="exampleModalLabel">Applicant details</h5>
                  <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div class="modal-body">
                <div className="row">
                  <div className="col">
                  Original Image
                  </div>
                  <div className="col">
                    <img src={ApplicantDetails?.photo_url} alt="Original" height="150" width="150" />
                  </div>
                  </div>
                  <div className="row">
                  <div className="col">
                  Captured Image
                  </div>
                  <div className="col">
                  <img src={ApplicantDetails?.UploadPhoto} alt="Original" height="150" width="150" />
                  </div>
                  </div>
                <div className="row">
                  <div className="col">
                  Applicant  Name
                  </div>
                  <div className="col">
                    {ApplicantDetails?.applicant_name}
                  </div>
                  </div>
                  <div className="row">
                  <div className="col">
                    Application Number
                  </div>
                  <div className="col">
                    {ApplicantDetails?.application_number}
                  </div>
                  </div>
                  <div className="row">
                    <div className="col">
                      Registration Number
                    </div>
                    <div className="col">
                      {ApplicantDetails?.registration_number}
                  </div>
                  </div>
                  {/* <div className="row">
                  <div className="col">
                    Roll Number
                  </div>
                  <div className="col">
                    {"value"}
                  </div>
                  </div> */}
                  <div className="row">
                  <div className="col">
                    Father Name
                  </div>
                  <div className="col">
                    {ApplicantDetails?.father_name}
                  </div>
                  </div>
                  <div className="row">
                  <div className="col">
                    Mother Name
                  </div>
                  <div className="col">
                    {ApplicantDetails?.mother_name}
                  </div>
                  </div>
                  <div className="row">
                  <div className="col">
                    Gender
                  </div>
                  <div className="col">
                    {ApplicantDetails?.gender}
                  </div>
                  </div>
                  <div className="row">
                  <div className="col">
                    Marital Status
                  </div>
                  <div className="col">
                    {ApplicantDetails?.marital_status}
                  </div>
                  </div>
                  <div className="row">
                  <div className="col">
                    Date of birth
                  </div>
                  <div className="col">
                    {ApplicantDetails?.dob}
                  </div>
                  </div>
                  <div className="row">
                  <div className="col">
                    Category
                  </div>
                  <div className="col">
                    {ApplicantDetails?.category}
                  </div>
                  </div>
                  <div className="row">
                  <div className="col">
                    Religion
                  </div>
                  <div className="col">
                    {ApplicantDetails?.religion}
                  </div>
                  </div>
                  <div className="row">
                  <div className="col">
                    Residents of Rajasthan
                  </div>
                  <div className="col">
                    {ApplicantDetails?.resident_of_rajasthan}
                  </div>
                  </div>
                  <div className="row">
                  <div className="col">
                    Home District
                  </div>
                  <div className="col">
                    {ApplicantDetails?.home_district}
                  </div>
                  </div>
                  <div className="row">
                  <div className="col">
                    Home State
                  </div>
                  <div className="col">
                    {ApplicantDetails?.home_state}
                  </div>
                  </div>
                  <div className="row">
                  <div className="col">
                    Nationality
                  </div>
                  <div className="col">
                    {ApplicantDetails?.nationality}
                  </div>
                  </div>
                  <div className="row">
                  <div className="col">
                    Address
                  </div>
                  <div className="col">
                    {ApplicantDetails?.address}
                  </div>
                  </div>
                  <div className="row">
                  <div className="col">
                    City
                  </div>
                  <div className="col">
                    {ApplicantDetails?.city}
                  </div>
                  </div>
                </div>
                <div class="modal-footer">
                  <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                </div>
              </div>
            </div>
          </div>

          {/* <div className="listingHeader">
           
          </div>
          <div className="tableBody">
            <div className="historyListing">
            
            </div>
            
          </div> */}
        </div>
      </div>
    </div>
  );
}

export default AllCustomers;
