import React, { useState } from "react";
import { Link} from "react-router-dom/cjs/react-router-dom.min";
import Header from "../common/Header.component";
import Footer from "../common/Footer.component";
import FormValidator from "../../_helpers/formValidator.helper";
import { useUserDispatch } from "../../context/loginContext";
import makeTheApiCall from '../../Api/axiosConfig'
import apiPath from '../../Api/apiPath';
import {toast} from 'react-toastify'
function LoginModal() {
  var userDispatch = useUserDispatch();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [flag, setFlag] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [validations, setValidations] = useState({});

  let search = window.location.search;
  let params = new URLSearchParams(search);
  let foo = params.get("id");

  const validator = new FormValidator([
    {
      field: "email",
      method: "isEmpty",
      validWhen: false,
      message: "Email is required.",
    },
    {
      field: "email",
      method: "isEmail",
      validWhen: true,
      message: "Email is not valid.",
    },
    {
      field: "password",
      method: "isEmpty",
      validWhen: false,
      message: "Password is required.",
    },
  ]);

  const signIn = (e) => {
    e.preventDefault();
    setSubmitted(true);
    const validation = validator.validate({ email, password });
    if (validation.isValid) {
      let apiOptions = {
        url :apiPath.Login,
        data :{
          email: email,
          password: password
        },
        method:"post"
      };
      makeTheApiCall(apiOptions).then((response)=>{
        let data = response.success;
        if(data.errors){
          toast.error(data.errors.msg);
          return;
        }
        
        if(data.user){
          localStorage.setItem("token_key", data.user?.token);
          userDispatch({ type: "LOGIN_SUCCESS" });
          window.location.reload();
        }
      else{
       alert("username and password doesn't match");  
      }
      setEmail("");
      setFlag(true);
      setPassword("");
    });
    } else {
      setValidations(validation);
    }
  };

  return(
    <div>
      <div className="container-fluid px-0">
        <div className="row">
          <div className="col">
            {/* header */}
            <Header />
          </div>
        </div>
      </div>
      <div className="container ">
        <div className="appointmentContainer mB50 clearfix ">
          
          <div className="dc-docpostholder appointmentForm loginPage mT15">
            <h4 className="modal-title text-center w-100">Welcome to Identity</h4>
           
            <div className="text-center w-100">
              
              {/* <img src={SocialLogin} alt="social login" /> */}
              <div className="textColor fz14 light">Please enter your details to login</div>
              <form className="dc-formtheme dc-loginform do-login-form">
                <fieldset>
                  <div className="form-group">
                    <input
                      type="text"
                      name="email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      placeholder="email"
                      className={
                        validations.email?.isInvalid
                          ? "form-control is-invalid"
                          : "form-control"
                      }
                    />
                    <span style={{ display: "block" }}>
                      {validations.email ? validations.email.message : ""}
                    </span>
                  </div>
                  {/* {validations.email?validations.email.message:false}
                  {JSON.stringify(validations)} */}
                  <div className="form-group">
                    <input
                      type="password"
                      name="password"
                      className={
                        validations.password?.isInvalid
                          ? "form-control is-invalid"
                          : "form-control"
                      }
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      placeholder="password"
                    />
                    <span style={{ display: "block" }}>
                      {validations.password ? validations.password.message : ""}
                    </span>
                  </div>

                  <div className="d-flex flex-column align-items-center w-100">
                    <Link
                      onClick={signIn}
                      className="minw140 dc-btn-primary radius3 fz16 medium mB20 mT5"
                    >
                      Login
                    </Link>
                  </div>
                </fieldset>
              </form>
            </div>
          </div>
        </div>
      </div>

      <div className="container-fluid px-0">
        <div className="row">
          <div className="col">
            {/* footer */}
            <Footer />
          </div>
        </div>
      </div>
    </div>
  );
}

export default LoginModal;
