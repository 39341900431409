import React,{ useState,useEffect} from "react";

import "../components/common/PopularSpecialties.component";
import HeaderUser from "../components/common/HeaderUser.component";
import Sidebar from "../components/common/Sidebar.component";
import makeTheApiCall from "../Api/axiosConfig";
import apiPath from "../Api/apiPath";
import {toast} from 'react-toastify';
import { useHistory } from "react-router-dom";

function EditSubAdmin() {
  const history = useHistory();
  const [ListOfClient, setListOfClient] = useState(null);
  const [InputFields, setInputFields] = useState({
    start_date: new Date(),
  });

  const HandleInputsFields = (name,value) => {
    setInputFields((prev)=>({
      ...prev,
      [name]: value,
    }))
  };
  const SubmitForm = (e) => {
    e.preventDefault();
    if (!InputFields.full_name) {
      toast.error("Name required!");
      return;
    }
    if (!InputFields.role) {
      toast.error("Role required!");
      return;
    }
    if (!InputFields.email) {
      toast.error("Email required!");
      return;
    }
    if (!InputFields.contact_number) {
      toast.error("Mobile required!");
      return;
    }
    // if (!InputFields.password) {
    //   toast.error("Password required!");
    //   return;
    // }
    let apiOptions = {
      url: apiPath.Edit_User,
      data: InputFields,
      method: "post",
    };
    makeTheApiCall(apiOptions).then((response) => {
      let responseObj = response.success ? response?.success?.data : null;
      if(responseObj){
        toast.success('User details updated.');
        history.replace('/sub-admin')
      }
    });
  };

  useEffect(() => {
    let apiOptions = {
      url: apiPath.getRoleDropDown,
      method: "post",
    };
    makeTheApiCall(apiOptions).then((response) => {
      let responseObj = response.success ? response?.success?.data : null;
      if (responseObj) {
        setListOfClient(responseObj);
      }
    });
  }, []);

  useEffect(() => {
    let projectId = history.location.search?.split('user_id=');
    let AssignedId= projectId[1];
    if(AssignedId){
        let apiOptions = {
        url: apiPath.User_Details,
        method: "get",
        params: {
            id:AssignedId
        }
        };
        makeTheApiCall(apiOptions).then((response) => {
        let responseObj = response.success ? response?.success?.data : null;
        if (responseObj) {
            setInputFields({
                id:responseObj?._id,
                full_name:responseObj?.full_name,
                role:responseObj?.role,
                email:responseObj?.email,
                contact_number:responseObj?.contact_number
            })
        }
        });
    }
  }, []);

  return (
    <div className="home">
      <HeaderUser />
      <Sidebar />
      <div className="employeeWraper">
        <div className="headingSec mB30">
          <h1>Sub Admin</h1>
        </div>
        <div className="profileTabs">
          <div className="profileInfo">
            <h3>Edit Sub Admin</h3>

            <div id="editInfo" class="accordion-body collapse show">
              <div class="accordion-inner">
                <div className="row">
                  <div className="col-md-12">
                    <form className="dc-formtheme  do-login-form">
                      <fieldset>
                        <div className="row">
                          <div className="col-md-6 col-lg-4">
                            <div className="form-group">
                              <label>Full Name</label>
                              <input
                                type="text"
                                name="full_name"
                                className="form-control"
                                placeholder="Geroge Martin"
                                value={InputFields?.full_name}
                                onChange={e=>HandleInputsFields('full_name',e.target.value)}
                              />
                            </div>
                          </div>

                          <div className="col-md-6 col-lg-4">
                            <div className="form-group">
                              <label>Email</label>
                              <input
                                type="email"
                                name="email"
                                value={InputFields?.email}
                                className="form-control"
                                placeholder="George.smith@mymail.com"
                                onChange={e=>HandleInputsFields('email',e.target.value)}
                              />
                            </div>
                          </div>

                          <div className="col-md-6 col-lg-4">
                            <div className="form-group">
                              <label>Mobile Number</label>
                              <input
                                type="text"
                                name="contact_number"
                                className="form-control"
                                placeholder="enter mobile number"
                                value={InputFields?.contact_number}
                                onChange={e=>HandleInputsFields('contact_number',e.target.value)}
                              />
                            </div>
                          </div>

                          <div className="col-md-6 col-lg-4">
                            <div className="form-group">
                              <label>Password</label>
                              <input
                                type="password"
                                name="password"
                                className="form-control"
                                placeholder="enter password"
                                onChange={e=>HandleInputsFields('password',e.target.value)}
                              />
                            </div>
                          </div>

                          <div className="col-md-6 col-lg-4">
                            <div className="form-group">
                              <label>Role</label>
                              <select
                                name="role"
                                className="form-control"
                                placeholder="select role"
                                value={InputFields?.role?._id}
                                onChange={e=>HandleInputsFields('role',e.target.value)}
                              >
                                <option value="">Select role</option>
                              {
                                  ListOfClient && ListOfClient?.map(client=>{
                                    return (
                                      <option value={client._id}>{client?.name}</option>
                                    )
                                  })
                                }
                              </select>
                            </div>
                          </div>
                        </div>
                      </fieldset>
                    </form>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <div className="col-md-12">
                      <div className="btnsGroup">
                        <button className="dc-btn cancelBtn">Cancel</button>
                        <button className="dc-btn dc-btn bookAppo dc-btn-primary uploadCv" onClick={e=>SubmitForm(e)} >
                          Add/Save Sub-Admin
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EditSubAdmin;
