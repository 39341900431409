import React, {useState,useEffect} from "react";
import HeaderUser from "../components/common/HeaderUser.component";
import Sidebar from "../components/common/Sidebar.component";
import makeTheApiCall from '../Api/axiosConfig'
import apiPath from '../Api/apiPath';
import {toast} from 'react-toastify'
import {useHistory} from 'react-router-dom'
function AllProjects() {
  const history= useHistory();
  const [TableData, setTableData] = useState(null);

  const DeleteProject = (id)=>{
    let response = window.confirm('Are you sure you want to delete ?');
    if(response){
      let apiOptions = {
        url :apiPath.Delete_Project,
        params :{
          id:id
        },
        method:"get"
      };
      makeTheApiCall(apiOptions).then((response)=>{
        let responseObj = response.success ? response?.success?.data : null;
        if(responseObj){
          toast.success('Project has been deleted');
          setTimeout(() => {
            window.location.reload();
          }, 2000);

        }else{
          toast.error('Please try again later');
        }
      });
    }
  };
  const StartProject=(id,status)=>{
    let response = window.confirm('Are you sure you want to start project ?');
    if(response){
      let apiOptions = {
        url :apiPath.Start_Project,
        params :{
          id:id
        },
        method:"get"
      };
      makeTheApiCall(apiOptions).then((response)=>{
        let responseObj = response.success ? response?.success?.data : null;
        if(responseObj){
          toast.success('Project has been started');
          setTimeout(() => {
            window.location.reload();
          }, 2000);

        }else{
          toast.error('failed to start project');
        }
      });
    }
  };
  
  const StopProject=(id,status)=>{
    let response = window.confirm('Are you sure you want to stop project ?');
    if(response){
      let apiOptions = {
        url :apiPath.Stop_Project,
        params :{
          id:id
        },
        method:"get"
      };
      makeTheApiCall(apiOptions).then((response)=>{
        let responseObj = response.success ? response?.success?.data : null;
        if(responseObj){
          toast.success('Project has been stopped');
          setTimeout(() => {
            window.location.reload();
          }, 2000);

        }else{
          toast.error('failed to stop project');
        }
      });
    }
  }
  useEffect(() => {
    let apiOptions = {
      url :apiPath.List_Project,
      data :{
        page: 1,
        limit: 1000
      },
      method:"post"
    };
    makeTheApiCall(apiOptions).then((response)=>{
      let responseObj = response.success ? response?.success?.data : null;
      if(responseObj){
        setTableData(responseObj)
      }
    });
  }, []);
  return (
    <div className="home">
      <HeaderUser />
      <Sidebar />
      <div className="employeeWraper">
        <div className="headingSec">
          <h1>All Projects</h1>
          <a href="/add-project" className="uploadCv">
            Add{" "}
          </a>
        </div>

        <div className="employyTable">
          <table>
            <thead className="">
              <tr className="">
              <th className="">#</th>
                <th className="">Name</th>
                <th className="">Assigned Client</th>
                <th className="">applicants</th>
                <th className="">Track</th>
                <th className="">Is start</th>
                <th className="">Status</th>
                <th className="">Manage</th>
              </tr>
            </thead>
            <tbody>
              {
                TableData && TableData?.map((obj,index)=>{
                  return (
                    <tr key={index}>
                      <td>{index+1}</td>
                      <td> {obj?.name}</td>
                      <td>{obj?.assigned_user?.full_name}</td>
                      <td>
                      <a href={``} onClick={e=>{
                        e.preventDefault();

                        // history.push({
                        //   pathname: `/customers`,
                        //   search: `?project_id=${obj._id}`
                        // })
                        history.push(`/customers?project_id=${obj._id}`, { project_id: obj._id });
                      }} >View Applicant</a>
                      </td>
                      <td>
                      <a href={``} onClick={e=>{
                        e.preventDefault();

                        // history.push({
                        //   pathname: `/customers`,
                        //   search: `?project_id=${obj._id}`
                        // })
                        history.push(`/customer-track?project_id=${obj._id}`, { project_id: obj._id });
                      }} >View Applicant Track</a>
                      </td>
                      <td>
                      <div class="form-check form-switch">
                        {/* {
                          obj?.is_start == 1 && (
                            <button className="actionBtn mr-2" onClick={e=>StopProject(obj?._id)}>
                              Yes
                            
                            </button>
                          )
                        } */}
                        {
                          obj?.is_start == 1 && (
                            <div class="container">
                              <label class="switch" for={obj?._id}>
                                <input type="checkbox" id={obj?._id} checked   onClick={e=>StopProject(obj?._id)}/>
                                <div class="slider round"></div>
                              </label>
                            </div>
                          )
                        }
                        {/* {
                          obj?.is_start == 0 && (
                            <button className="actionBtn mr-2" onClick={e=>StartProject(obj?._id)}>
                              No
                            </button>
                          )
                        } */}
                        {
                          obj?.is_start == 0 && (
                            <div class="container">
                              <label class="switch" for={obj?._id}>
                                <input type="checkbox" id={obj?._id}  onClick={e=>StartProject(obj?._id)}/>
                                <div class="slider round"></div>
                              </label>
                            </div>
                          )
                        }
                      </div>
                      
                    </td>
                    <td>
                    <div class="form-check form-switch">
                        <input class="form-check-input" type="checkbox" id={obj?._id} checked={obj?.status == 1 ? true:false}/>
                        <label class="form-check-label" for={obj?._id}>{obj?.status == 1 ? "Active" : "Deactive"}</label>
                    </div>
                    </td>
                    <td>
                      <span className="d-inline-block" tabindex={obj?._id} data-toggle="tooltip" title="Edit">
                        <button className="actionBtn mr-2" onClick={e=>history.push(`/edit-project?project-id=${obj?._id}`)} >
                          <span class="lnr lnr-pencil "></span>
                        </button>
                      </span>
                      <span className="d-inline-block" tabindex={obj?._id} data-toggle="tooltip" title="Delete">
                      <button className="actionBtn" onClick={e=>DeleteProject(obj?._id)} >
                        <span class="lnr lnr-trash"></span>
                      </button>
                      </span>
                    </td>
                  </tr>
                  )
                })
              }
              <tr>
                <td className="blankTd"> </td>
              </tr>
            </tbody>
          </table>

          {/* <div className="listingHeader">
           
          </div>
          <div className="tableBody">
            <div className="historyListing">
            
            </div>
            
          </div> */}
        </div>
      </div>
    </div>
  );
}

export default AllProjects;
