import React from "react";
import { Link } from "react-router-dom";
const Logo = "/assets/images/ID_logo.png";
function HeaderUser() {
  return (
    <header id="dc-header" className="dc-header dc-haslayout employHeader">
      <div className="dc-topbar" style={{ backgroundColor: "#FFFFFF" }}>
        <div className="fluid">
          <nav className="navbar navbar-expand-lg navbar-light bg-light">
            <Link className="navbar-brand" to="/">
              <img src={Logo} alt="company's Logo" />
            </Link>
            <button
              className="navbar-toggler"
              type="button"
              data-toggle="collapse"
              data-target="#navbarText"
              aria-controls="navbarText"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse" id="navbarText">
              <ul className="navbar-nav ml-auto">           
                <li className="nav-item">
                  <Link className="nav-link" to="">
                    <span class="ti-bell"></span>
                  </Link>
                </li>
                <li className="nav-item dropdown ">
                  <Link
                    className="nav-link dropdown-toggle"
                    to=""
                    role="button"
                    id="dropdownMenuLink"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <span className="profileImg">
                    <img src={Logo} alt="company's Logo" />
                    </span>
                    Admin
                  </Link>
                  <div class="dropdown-menu" aria-labelledby="dropdownMenuLink">
                    <a class="dropdown-item" href="/settings">
                      Edit Profile
                    </a>
                    <a class="dropdown-item" href="#" onClick={e=>{e.preventDefault(); localStorage.clear();window.location.reload()}} >
                      Logout
                    </a>
                  </div>
                </li>
              </ul>
            </div>
          </nav>
        </div>
        {/* </div> */}
      </div>
    </header>
  );
}

export default HeaderUser;
