import React from "react";
import { Link } from "react-router-dom";

import Button from "../interface/Button.component";
import UserService from "../../_services/user.service";
const Logo = "/assets/images/ID_logo.png";
function Header() {
  return (
    <header id="dc-header" className="dc-header dc-haslayout">
      <div className="dc-topbar" style={{ backgroundColor: "#FFFFFF" }}>
        <div className="container">
          <nav className="navbar navbar-expand-lg navbar-light bg-light">
            <Link className="navbar-brand" to="/">
              <img src={Logo} alt="company's Logo" />
            </Link>
            <button
              className="navbar-toggler"
              type="button"
              data-toggle="collapse"
              data-target="#navbarText"
              aria-controls="navbarText"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse" id="navbarText">
              <ul className="navbar-nav ml-auto">
                <li className="nav-item">
                  <Link className="nav-link" to="/">
                    Home
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" to="/about">
                    About us
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" to="/contact">
                    Contact Us
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" to="/privacy">
                    Privacy Policy
                  </Link>
                </li>
               
                <li className="nav-item">
                  <Link className="nav-link" to="terms">
                  Terms & Conditions
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" to="/faq">
                  FAQ
                  </Link>
                </li>
              </ul>
              {!UserService.isLoggedIn ? (
                <Button
                  type="tertiary"
                  className="col-2"
                  text="Login"
                  to="/login"
                />
              ) : (
                <Button
                  type="tertiary"
                  className="col-2"
                  text="Logout"
                  onClick={UserService.logout}
                />
              )}
            </div>
          </nav>
          {/* <div className="row">
            <div className="col-1"></div>
            <div className="col">
              <div className="d-flex align-items-center justify-content-around">
                <div className="col-2">
                  <Link to="">
                    <img src={Logo} alt="company's Logo" />
                  </Link>
                </div>
                <div className="col-2"></div>
                <div className="col-4 d-flex align-items-center justify-content-between">
                  <Link className="mt-4" to="">
                    <p>List Your Practice</p>
                  </Link>

                  <Link className="mt-4" to="">
                    <p>Book Appointment</p>
                  </Link>

                  <Link className="mt-4" to="">
                    <p>Contact Us</p>
                  </Link>

                  <Link className="mt-4" to="">
                    <p>Help</p>
                  </Link>
                </div>
                <div className="col-2">
                
                </div>
              </div>
            </div>

            <div className="col-1"></div>
          </div> */}
        </div>
        {/* </div> */}
      </div>
    </header>
  );
}

export default Header;
