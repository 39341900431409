import React, { useState } from "react";
import { Link } from "react-router-dom";

import SearchModal from "./SearchModal.component";

const Search = "/assets/images/search.svg";
const HeaderBackground = "/assets/images/headerBkg.svg";
const SearchBackground = "/assets/images/SliderImage.png";

const SearchBar2 = ({ home }) => {
  const [name, setName] = useState("");
  const [location, setLocation] = useState("");
  const [date, setDate] = useState("");
  const [insurance, setInsurance] = useState("");

  if (home) {
    return (
      <div>
        <div
          className="dc-advancedsearch-container d-flex flex-column align-items-center justify-content-center "
          style={{
            backgroundImage: `url(${SearchBackground})`,
            // backgroundSize: "contain",
            backgroundPosition: "center",
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
          }}
        >
         
        </div>
      </div>
    );
  }
  return (
    <div
      className="dc-advancedsearch-container innerPage"
      style={{
        backgroundImage: `url(${HeaderBackground})`,
        // backgroundSize: "contain",
        backgroundPosition: "center",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
      }}
    >
      <div className="container">
        <div
          className="dc-advancedsearch clearfix "
          style={{ borderRadius: "100px" }}
        >
          <div className="row align-items-center ">
            <div className="col-md-3 hideMobile">
              <div className="d-flex justify-content-left align-items-center">
                <span className="body-title-searchbar-primary ml-4 mr-2">
                  Find
                </span>
                <span className="body-title-searchbar-primary mr-2">A</span>
                <span className="body-title-searchbar-secondary ">Doctor</span>
              </div>
            </div>
            <div className="col-md-9">
              <form className="dc-formtheme dc-form-advancedsearch searchPage d-flex clearfix">
                <div className="form-group">
                  <label>
                    Name
                    <input
                      type="text"
                      name="search"
                      className="form-control"
                      placeholder="Search doctors, clinics, hospitals, etc."
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                    />
                  </label>
                </div>
                <div className="form-group">
                  <label>
                    Date
                    <input
                      type="date"
                      name="appointment"
                      className="form-control"
                      placeholder="Appointment"
                      value={date}
                      onChange={(e) => setDate(e.target.value)}
                    />
                  </label>
                </div>
                <div className="form-group">
                  <label>
                    Location
                    <div className="dc-select">
                      <select
                        className="chosen-select locations"
                        data-placeholder="Country"
                        name="locations"
                        style={{ display: "none" }}
                        value={location}
                        onChange={(e) => setLocation(e.target.value)}
                      >
                        <option value="Location">Location*</option>
                        <option value="United State">United State</option>
                        <option value="Canada">Canada</option>
                        <option value="England">England</option>
                        <option value="Switzerland">Switzerland</option>
                        <option value="New Zealand">New Zealand</option>
                      </select>
                    </div>
                  </label>
                </div>

                <div className="form-group">
                  <label>
                    Insurance
                    <div className="dc-select">
                      <select
                        className="chosen-select locations insurance"
                        data-placeholder="Insurance"
                        name="insurance"
                        style={{ display: "none" }}
                        value={insurance}
                        onChange={(e) => setInsurance(e.target.value)}
                      >
                        <option value="Insrance">Insurance*</option>
                        <option value="HDFC">HDFC</option>
                        <option value="Canada">Canada</option>
                        <option value="England">England</option>
                        <option value="Switzerland">Switzerland</option>
                        <option value="New Zealand">New Zealand</option>
                      </select>
                    </div>
                  </label>
                </div>
                <Link to="/search">
                  <button className="searchBtn">
                    {" "}
                    <img src={Search} alt="search" />
                  </button>
                </Link>
              </form>

              <div
                className="mobileSearch"
                data-toggle="modal"
                data-target="#searchModal"
              >
                Click here to search you doctor...
                <Link to="/search">
                  <button className="searchBtn">
                    {" "}
                    <img src={Search} alt="search" />
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>

      <SearchModal />
    </div>
  );
};

export default SearchBar2;
