import React from "react";

import "../components/common/PopularSpecialties.component";
import Footer from "../components/common/Footer.component";
import Header from "../components/common/Header.component";
import GetTheApp from "../components/common/GetTheApp.component";
import AreYouADoctor from "../components/common/ForDoctors.component";
import UsersFeedback from "../components/common/UsersFeedback.component";
import SearchBar2 from "../components/search/SearchBar.component";


const DoctorsNetworkIcon = "/assets/images/customer.png";
const AppointmentIcon = "/assets/images/customer.png";
const PatientIcon = "/assets/images/customer.png";

function Home() {
  return (
    <div className="home">
      <section className="dc-haslayout homeBanner">
        <div className="">
          <SearchBar2 home />
        </div>
      </section>
      
    </div>
  );
}

export default Home;
