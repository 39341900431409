import React from "react";
import "../components/common/PopularSpecialties.component";
const checkIcon = "/assets/images/check-cricle.png";
function HomeSuccess() {
  return (
    <div className="home">
      <body class="form-v5">
        <div class="page-content">
          <div
            class="form-v5-content customerOuter"
            style={{ marginTop: "20px" }}
          >
            <div className="circle">
              <div className="text-center mb-3">
                <img src={checkIcon} alt="" />
              </div>
              <h5 className="success-text">Details captured successfully.</h5>
              {/* <h6 className="success-text">Tell us what you think.</h6> */}
              {/* <form>
                <div class="formbold-mb-6">
                  <label for="qusOne" class="formbold-form-label">
                    How would you rate your overall experience with our Service?
                  </label>

                  <div class="formbold-radio-flex">
                    <div class="formbold-radio-group">
                      <label class="formbold-radio-label">
                        <input
                          class="formbold-input-radio"
                          type="radio"
                          name="qusOne"
                          id="qusOne"
                        />
                        Very Good
                        <span class="formbold-radio-checkmark"></span>
                      </label>
                    </div>

                    <div class="formbold-radio-group">
                      <label class="formbold-radio-label">
                        <input
                          class="formbold-input-radio"
                          type="radio"
                          name="qusOne"
                          id="qusOne"
                        />
                        Good
                        <span class="formbold-radio-checkmark"></span>
                      </label>
                    </div>

                    <div class="formbold-radio-group">
                      <label class="formbold-radio-label">
                        <input
                          class="formbold-input-radio"
                          type="radio"
                          name="qusOne"
                          id="qusOne"
                        />
                        Fair
                        <span class="formbold-radio-checkmark"></span>
                      </label>
                    </div>

                    <div class="formbold-radio-group">
                      <label class="formbold-radio-label">
                        <input
                          class="formbold-input-radio"
                          type="radio"
                          name="qusOne"
                          id="qusOne"
                        />
                        Poor
                        <span class="formbold-radio-checkmark"></span>
                      </label>
                    </div>

                    <div class="formbold-radio-group">
                      <label class="formbold-radio-label">
                        <input
                          class="formbold-input-radio"
                          type="radio"
                          name="qusOne"
                          id="qusOne"
                        />
                        Very Poor
                        <span class="formbold-radio-checkmark"></span>
                      </label>
                    </div>
                  </div>
                </div>

                <div class="formbold-mb-6">
                  <label for="qusTwo" class="formbold-form-label">
                    How satisfied are you with the comprehensive of our offer?
                  </label>

                  <div class="formbold-radio-flex">
                    <div class="formbold-radio-group">
                      <label class="formbold-radio-label">
                        <input
                          class="formbold-input-radio"
                          type="radio"
                          name="qusTwo"
                          id="qusTwo"
                        />
                        Very Good
                        <span class="formbold-radio-checkmark"></span>
                      </label>
                    </div>

                    <div class="formbold-radio-group">
                      <label class="formbold-radio-label">
                        <input
                          class="formbold-input-radio"
                          type="radio"
                          name="qusTwo"
                          id="qusTwo"
                        />
                        Good
                        <span class="formbold-radio-checkmark"></span>
                      </label>
                    </div>

                    <div class="formbold-radio-group">
                      <label class="formbold-radio-label">
                        <input
                          class="formbold-input-radio"
                          type="radio"
                          name="qusTwo"
                          id="qusTwo"
                        />
                        Fair
                        <span class="formbold-radio-checkmark"></span>
                      </label>
                    </div>

                    <div class="formbold-radio-group">
                      <label class="formbold-radio-label">
                        <input
                          class="formbold-input-radio"
                          type="radio"
                          name="qusTwo"
                          id="qusTwo"
                        />
                        Poor
                        <span class="formbold-radio-checkmark"></span>
                      </label>
                    </div>

                    <div class="formbold-radio-group">
                      <label class="formbold-radio-label">
                        <input
                          class="formbold-input-radio"
                          type="radio"
                          name="qusTwo"
                          id="qusTwo"
                        />
                        Very Poor
                        <span class="formbold-radio-checkmark"></span>
                      </label>
                    </div>
                  </div>
                </div>

                <div class="formbold-mb-6">
                  <label for="qusThree" class="formbold-form-label">
                    How satisfied are you with the customer support of our
                    Service?
                  </label>

                  <div class="formbold-radio-flex">
                    <div class="formbold-radio-group">
                      <label class="formbold-radio-label">
                        <input
                          class="formbold-input-radio"
                          type="radio"
                          name="qusThree"
                          id="qusThree"
                        />
                        Very Good
                        <span class="formbold-radio-checkmark"></span>
                      </label>
                    </div>

                    <div class="formbold-radio-group">
                      <label class="formbold-radio-label">
                        <input
                          class="formbold-input-radio"
                          type="radio"
                          name="qusThree"
                          id="qusThree"
                        />
                        Good
                        <span class="formbold-radio-checkmark"></span>
                      </label>
                    </div>

                    <div class="formbold-radio-group">
                      <label class="formbold-radio-label">
                        <input
                          class="formbold-input-radio"
                          type="radio"
                          name="qusThree"
                          id="qusThree"
                        />
                        Fair
                        <span class="formbold-radio-checkmark"></span>
                      </label>
                    </div>

                    <div class="formbold-radio-group">
                      <label class="formbold-radio-label">
                        <input
                          class="formbold-input-radio"
                          type="radio"
                          name="qusThree"
                          id="qusThree"
                        />
                        Poor
                        <span class="formbold-radio-checkmark"></span>
                      </label>
                    </div>

                    <div class="formbold-radio-group">
                      <label class="formbold-radio-label">
                        <input
                          class="formbold-input-radio"
                          type="radio"
                          name="qusThree"
                          id="qusThree"
                        />
                        Very Poor
                        <span class="formbold-radio-checkmark"></span>
                      </label>
                    </div>
                  </div>
                </div>

                <div class="formbold-mb-3">
                  <label for="message" class="formbold-form-label">
                    What should we change in oirder to live up to your
                    expectations?
                  </label>
                  <textarea
                    rows="6"
                    name="message"
                    id="message"
                    class="formbold-form-input"
                  ></textarea>
                </div>

                <div>
                  <label for="email" class="formbold-form-label">
                    {" "}
                    Email <span>
                      (Only if you want to hear more from us)
                    </span>{" "}
                  </label>
                  <input
                    type="email"
                    name="email"
                    id="email"
                    class="formbold-form-input"
                  />
                </div>
              </form>
              <div>
                <button className="btn btn-primary mt-3">Submit</button>
              </div> */}
              {/* <p className="lorem-text">
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy
                text ever since the 1500s,
              </p> */}
            </div>
            <div className="clearfix"></div>
          </div>
          <div className="clearfix"></div>
        </div>
      </body>
    </div>
  );
}

export default HomeSuccess;
