import React , {useState , useEffect} from "react";
import HeaderUser from "../components/common/HeaderUser.component";
import Sidebar from "../components/common/Sidebar.component";
import makeTheApiCall from '../Api/axiosConfig'
import apiPath from '../Api/apiPath';
import {useHistory} from 'react-router-dom';
function HomeEmployee() {
  const [TableData, setTableData] = useState(null);
  const history= useHistory();
  useEffect(() => {
    let apiOptions = {
      url :apiPath.Dashboard_Data,
      data :{
        page: 1,
        limit: 1000
      },
      method:"post"
    };
    makeTheApiCall(apiOptions).then((response)=>{
      let responseObj = response.success ? response?.success : null;
      if(responseObj){
        setTableData(responseObj)
      }
    });
  }, []);
  return (
    <div className="home">
      <HeaderUser />
      <Sidebar/>
      <div className="employeeWraper">
        <div className="row">
        <div className="col">
          <div class="card bg-secondary">
           
            <div class="container" onClick={e=>history.push('/projects')} >
              <h4 className="text-center text-dark"><b>Total Projects</b></h4> 
              <p className="text-center text-dark">{TableData?.total_project}</p> 
            </div>
          </div>
        </div>
        <div className="col">
          <div class="card bg-success">
          
            <div class="container" onClick={e=>history.push('/customers')}>
              <h4  className="text-center text-dark"><b>Total Applicants</b></h4> 
              <p className="text-center text-dark">{TableData?.total_applicant}</p> 
            </div>
          </div>
        </div>
        <div className="col">
          <div class="card bg-primary text-dark">
           
            <div class="container" onClick={e=>history.push('/customer-track')}> 
              <h4  className="text-center text-dark"><b>Total verified</b></h4> 
              <p className="text-center text-dark">{TableData?.verified_applicant}</p> 
            </div>
          </div>
        </div>
        
        </div>
      </div>
    </div>
  );
}

export default HomeEmployee;
