import React,{ useState,useEffect} from "react";

import "../components/common/PopularSpecialties.component";
import HeaderUser from "../components/common/HeaderUser.component";
import Sidebar from "../components/common/Sidebar.component";
import makeTheApiCall from "../Api/axiosConfig";
import apiPath from "../Api/apiPath";
import {toast} from 'react-toastify';
import { useHistory } from "react-router-dom";

function AddSubAdmin() {
  const history = useHistory();
  const [InputFields, setInputFields] = useState({
    start_date: new Date(),
  });

  const HandleInputsFields = (name,value) => {
    setInputFields((prev)=>({
      ...prev,
      [name]: value,
    }))
  };
  const SubmitForm = (e) => {
    e.preventDefault();
    InputFields.role="5f43044cea58680b7c9d73ae";
    if (!InputFields.full_name) {
      toast.error("Name required!");
      return;
    }
    if (!InputFields.email) {
      toast.error("Email required!");
      return;
    }
    if (!InputFields.contact_number) {
      toast.error("Mobile required!");
      return;
    }
    if (!InputFields.password) {
      toast.error("Password required!");
      return;
    }
    let apiOptions = {
      url: apiPath.Create_User,
      data: InputFields,
      method: "post",
    };
    makeTheApiCall(apiOptions).then((response) => {
      let responseObj = response.success ? response?.success?.data : null;
      if(responseObj){
        toast.success('A new client is added.');
        history.replace('/Clients')
      }
    });
  };

  return (
    <div className="home">
      <HeaderUser />
      <Sidebar />
      <div className="employeeWraper">
        <div className="headingSec mB30">
          <h1>Client</h1>
        </div>
        <div className="profileTabs">
          <div className="profileInfo">
            <h3>Add Client</h3>

            <div id="editInfo" class="accordion-body collapse show">
              <div class="accordion-inner">
                <div className="row">
                  <div className="col-md-12">
                    <form className="dc-formtheme  do-login-form">
                      <fieldset>
                        <div className="row">
                          <div className="col-md-6 col-lg-4">
                            <div className="form-group">
                              <label>Full Name</label>
                              <input
                                type="text"
                                name="full_name"
                                className="form-control"
                                placeholder="Geroge Martin"
                                onChange={e=>HandleInputsFields('full_name',e.target.value)}
                              />
                            </div>
                          </div>

                          <div className="col-md-6 col-lg-4">
                            <div className="form-group">
                              <label>Email</label>
                              <input
                                type="email"
                                name="email"
                                className="form-control"
                                placeholder="George.smith@mymail.com"
                                onChange={e=>HandleInputsFields('email',e.target.value)}
                              />
                            </div>
                          </div>

                          <div className="col-md-6 col-lg-4">
                            <div className="form-group">
                              <label>Mobile Number</label>
                              <input
                                type="text"
                                name="contact_number"
                                className="form-control"
                                placeholder="enter mobile number"
                                onChange={e=>HandleInputsFields('contact_number',e.target.value)}
                              />
                            </div>
                          </div>

                          <div className="col-md-6 col-lg-4">
                            <div className="form-group">
                              <label>Password</label>
                              <input
                                type="password"
                                name="password"
                                className="form-control"
                                placeholder="enter password"
                                onChange={e=>HandleInputsFields('password',e.target.value)}
                              />
                            </div>
                          </div>

                        </div>
                      </fieldset>
                    </form>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <div className="col-md-12">
                      <div className="btnsGroup">
                        <button className="dc-btn cancelBtn">Cancel</button>
                        <button className="dc-btn dc-btn bookAppo dc-btn-primary uploadCv" onClick={e=>SubmitForm(e)} >
                          Add/Save
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddSubAdmin;
