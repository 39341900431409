import React from "react";

import "../components/common/PopularSpecialties.component";
import HeaderUser from "../components/common/HeaderUser.component";
import Sidebar from "../components/common/Sidebar.component";
const cvIcon = "/assets/images/uploadcv.svg";

function EditCustomers() {
  return (
    <div className="home">
      <HeaderUser />
      <Sidebar/>
        <div className="employeeWraper">
                <div className="headingSec mB30"> 
                    <h1>Edit Customers</h1>  
                   
                </div>
                <div className="profileTabs">
                   
                <div className="profileInfo">
                <h3>Add/Edit Staff</h3>
         
            <div id="editInfo" class="accordion-body collapse show">
              <div class="accordion-inner">
                <div className="row">
                  <div className="col-md-12">
                    <form className="dc-formtheme  do-login-form">
                      <fieldset>
                        <div className="row">
                          <div className="col-md-6 col-lg-4">
                            <div className="form-group">
                              <label>First Name</label>
                              <input
                                type="text"
                                name="username"
                                className="form-control"
                                placeholder="Geroge"
                              />
                            </div>
                          </div>

                          <div className="col-md-6 col-lg-4">
                            <div className="form-group">
                              <label>Last Name</label>
                              <input
                                type="text"
                                name="username"
                                className="form-control"
                                placeholder="Smith"
                              />
                            </div>
                          </div>

                          <div className="col-md-6 col-lg-4">
                          <div className="form-group">
                              <label>Email</label>
                              <input
                                type="text"
                                name="username"
                                className="form-control"
                                placeholder="George.smith@mymail.com"
                              />
                            </div>
                          </div>


                          <div className="col-md-6 col-lg-4">
                            <div className="form-group">
                              <label>Mobile Number</label>
                              <input
                                type="password"
                                name="username"
                                className="form-control"
                                placeholder="**********"
                              />
                            </div>
                          </div>

                          <div className="col-md-6 col-lg-4">
                            <div className="form-group">
                              <label>Role</label>
                              <input
                                type="password"
                                name="username"
                                className="form-control"
                                placeholder="**********"
                              />
                            </div>
                          </div>

                          <div className="col-md-6 col-lg-4">
                          <div className="form-group">
                              <label>Assigned project</label>
                              <input
                                type="password"
                                name="username"
                                className="form-control"
                                placeholder="**********"
                              />
                            </div>
                          </div>

                          
                        </div>

                        <ul className="dc-accountinfo deactiveAccount">
                          <li>
                              <span className="pr-2">Active</span>
                            <div className="dc-on-off pull-left">
                              <input
                                type="checkbox"
                                id="hide-on"
                                name="contact_form"
                              />
                              <label for="hide-on">
                                <i></i>
                              </label>
                            </div>
                            <span>Deactivate</span>
                          </li>
                          </ul>

                      </fieldset>
                    </form>
                  </div>
                 
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <div className="col-md-12">
                            <div className="btnsGroup">
                            <button className="dc-btn cancelBtn">
                                Cancel
                             </button>
                      <button className="dc-btn dc-btn bookAppo dc-btn-primary uploadCv">
                      Add/Save Staff
                      </button>
                            </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          
          
          </div>
          </div>
                
        </div>
     
    </div>
  );
}

export default EditCustomers;
