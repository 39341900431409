

class UserService {
  _isLoggedIn = false;

  constructor() {
  }

  login(data, callback) {
    try {
     
    } catch (error) {
      
    }
  }

  logout(callback) {
    
  }

  forgotPassword(data, callback) {
    
  }

  resetPassword(data, callback) {
   
  }

  signup(data, callback) {
    
  }

  confirmSignUp(data, callback) {
    
  }

  resendSignUp(data, callback) {
    
  }

  get isLoggedIn() {
    return this._isLoggedIn;
  }
}

const instance = new UserService();

export default instance;
