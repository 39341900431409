let appMode = "production";
let newlive = "https://api.idtfy.in/api/";
let stage = "http://localhost:7070/api/";
let URL = appMode === "production" ? newlive : stage;

let pathApiObj = {
  baseURL: URL,
  NO_IMAGE:"https://api.instastationers.com/uploads/no_image_found.jpg",
  PROFILE:"https://api.instastationers.com/uploads/profile/",
  LOGO:"https://api.instastationers.com/uploads/deals/",
  
  //Login api
  Login               : "users/login",
  Upload_to_s3        : 'applicant/upload-capture-image',
  Upload_Video_to_s3  : "applicant/upload-capture-video",
  Dashboard_Data      : 'applicant/dashboard',

  //projects api
  Create_Project      : "projects/create",
  List_Project        : "projects/list",
  User_DropDown       : "users/drop-down",
  Project_DropDown    : "projects/drop-down",
  Edit_Project        : "projects/edit",
  Delete_Project      : "projects/delete",
  Start_Project       : "projects/start",
  Stop_Project       : "projects/stop",
  SMS_LIST            : "projects/sms-list",
  EMAIL_LIST          : "projects/email-list",

  //Applicant_Project
  Create_Applicant      : "applicant/create",
  Get_Applicant_List    : "applicant/list",
  Get_Applicant_Details : "applicant/detail",
  GET_Applicant_Public  : "applicant/public-detail",
  Get_Applicant_Validate: "applicant/public-detail-validate",
  Applicant_OTP_Validate: "otp/public-detail-validate",
  Applicant_OTP_GENERATE: "otp/send-otp",
  Get_Redirect_Url      : "applicant/public-url",
  Delete_Applicant      : "applicant/delete",
  Notify_Applicant      : "applicant/notify",
  Upload_Photo          : "applicant/upload",
  Applicant_Status      : "applicant/update-status",
  generateReport        : "applicant/export-employee",
  reportTable           : "applicant/report-table",

  /**User Module */
  getUserListing      : "users/list-subadmin",
  Create_User         : "users/create",
  Delete_User         : "users/delete",
  Edit_User           : "users/update",
  User_Details        : "users/detail",
  Logged_User_Details : "users/user",
  getClientListing    : "users/list-client",

  // contact us form
  Submit_Contact      : "contact/create",
  Get_Contact_List    : "contact/list",
  Delete_Contact      : "contact/delete",

  //role 
  getRoleDropDown     : "roles/list",
  Create_Role         : "roles/create",
  Delete_Role         : "roles/delete",


  usersDetailsLogs    : "api/admin/users/logs",
  addClient           : "api/admin/users/client/add",
  editClient          : "api/admin/users/client/edit",
  getEmployeeListing  : "api/admin/users/employee/listing",
  addEmployee         : "api/admin/users/employee/add",
  editEmployee        : "api/admin/users/employee/edit",
  deleteUser          : "api/admin/users/delete/user",
  changeUserStatus    : "api/admin/user/change/user/status",

};
export default pathApiObj;
