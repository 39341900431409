import React, {useState,useEffect} from "react";
import HeaderUser from "../components/common/HeaderUser.component";
import Sidebar from "../components/common/Sidebar.component";
import makeTheApiCall from '../Api/axiosConfig'
import apiPath from '../Api/apiPath';
import {toast} from 'react-toastify'
import {useHistory} from 'react-router-dom'

function ReportPage() {
  const [TableData, setTableData] = useState(null);
  const [ProjectList, setProjectList] = useState(null);
  const [ApplicantId, setApplicantId] = useState(null);
  const [searchValue,setSearchValue] = useState({});

  const exportFile=()=>{
    if(!searchValue?.project_id){
      toast.warning('Please select project.')
      return;
    }
    let url = `${apiPath.baseURL+apiPath.generateReport}?project_id=${searchValue?.project_id ? searchValue?.project_id :''}&track_status=${searchValue?.track_status ? searchValue?.track_status:''}&email_status=${searchValue?.email_status ?searchValue?.email_status : ''}&sms_status=${searchValue?.sms_status? searchValue?.sms_status :''}&project_status=${searchValue?.project_status ? searchValue?.project_status:""}`
    window.open(url);
    return
  };

  const handleFields = (name, value) => {
    setSearchValue(prev => ({
      ...prev,
      [name]: value,
    }));
  }

  useEffect(() => {
    if(searchValue?.project_id){
      let apiOptions = {
        url :apiPath.reportTable,
        method: "get",
        params: searchValue
      };
      makeTheApiCall(apiOptions).then((response)=>{
        let responseObj = response.success ? response?.success?.data : null;
        if(responseObj){
          setTableData(responseObj)
        }
      });
    }
  }, [searchValue]);

  useEffect(() => {
    let apiOptions = {
      url :apiPath.Project_DropDown,
      data :{
        page: 1,
        limit: 1000,
      },
      method:"post"
    };
    makeTheApiCall(apiOptions).then((response)=>{
      let responseObj = response.success ? response?.success?.data : null;
      if(responseObj){
        setProjectList(responseObj)
      }
    });
  }, []);

  const [ApplicantDetails, setApplicantDetails] = useState(null);
  useEffect(() => {
    if(ApplicantId){
      setApplicantDetails({})
      let apiOptions = {
        url :apiPath.Get_Applicant_Details,
        params :{
          id:ApplicantId
        },
        method:"get"
      };
      makeTheApiCall(apiOptions).then((response)=>{
        let responseObj = response.success ? response?.success?.data : null;
        
        if(responseObj){
          setApplicantDetails(responseObj)
        }
      });
    }
  }, [ApplicantId]);
  return (
    <div className="home">
      <HeaderUser />
      <Sidebar />
      <div className="employeeWraper">
        <div className="headingSec">
          <h1>Reports</h1>
        </div>

        <div className="employyTable">
        <select name="project_id" className="left mr-2" onChange={e=>handleFields('project_id',e.target.value)}>
            <option value="">Select Project</option>
            {
              ProjectList && ProjectList?.map((list,key)=>{
                return (
                  <option value={list._id}>{list?.name}</option>
                )
              })
            }
          </select>

          <select name="track_status" className="left mr-2"  onChange={e=>handleFields('track_status',e.target.value)}>
            <option value="">Select Track status</option>
            <option value={0}>waiting</option>
            <option value={1}>Dob verified</option>
            <option value={2}>Image captured</option>
            <option value={3}>Video captured</option>
          </select>
          <select name="project_status" className="left mr-2" onChange={e=>handleFields('project_status',e.target.value)}>
            <option value="">Project Status</option>
            <option value={0}>Not started</option>
            <option value={1}>Started</option>
          </select>

          <select name="sms_status" className="left mr-2"  onChange={e=>handleFields('sms_status',e.target.value)}>
            <option value="">SMS Status</option>
            <option value={1}>Success</option>
            <option value={0}>Failed</option>
          </select>
          <select name="email_status" className="left mr-2"  onChange={e=>handleFields('email_status',e.target.value)}>
            <option value="">Email status</option>
            <option value={1}>email send</option>
            <option value={0}>email failed</option>
          </select>
        </div>

          <button className="uploadCv mT10" onClick={exportFile}>
           Generate Excel
            </button>
            <button className="uploadCv mT10" >
           Generate PDF
            </button>

            <div className="employyTable">
          <table>
            <thead className="">
              <tr className="">
              <th className="">#</th>
                <th className="">Name</th>
                <th className="">Reg Number</th>
                <th className="">Verified status</th>
                <th className="">Track Status</th>
                <th className="">Match %</th>
                <th className="">Sms Send</th>
                <th className="">Email Send</th>
                <th className=""> Manage </th>
              </tr>
            </thead>
            <tbody>
            {
                TableData && TableData?.map((obj,index)=>{
                  return (
                    <tr>
                      <td>{index+1}</td>
                      <td>{obj?.applicant_name}</td>
                      <td>{obj?.registration_number}</td>
                      <td>{obj?.is_completed === 0 ? 'Waiting' : 'Completed'}</td>
                      <td>{obj?.step_completed === 0 ? 'Step 0' : (obj?.step_completed === 1 ? 'Step 1' : (obj?.step_completed === 2 ? 'Step 2' : 'Step3'))}</td>
                      <td>{obj?.match_count?.FaceMatches?.length>0 ? obj?.match_count?.FaceMatches[0].Similarity : '0'}  </td>
                      <td>{obj?.sms_send === 0 ? "No" : 'Yes'}</td>
                      <td>{obj?.email_send === 0 ?'No': 'Yes'}</td>
                      <td>
                      <span className="d-inline-block" tabindex={obj?._id} data-toggle="tooltip" title="View">
                      <button className="actionBtn mr-2" onClick={e=>setApplicantId(obj._id)} >
                        <span class="lnr lnr-eye " data-toggle="modal" data-target="#exampleModal"></span>
                      </button>
                      </span>
                      </td>
                    </tr>
                  )
                })
              }
              <tr>
                <td className="blankTd"> </td>
              </tr>
              
            </tbody>
          </table>

      </div>
      </div>
      {/* modal */}
      <div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog" role="document">
              <div class="modal-content">
                <div class="modal-header">
                  <h5 class="modal-title" id="exampleModalLabel">Applicant details</h5>
                  <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div class="modal-body">
                <div className="row">
                  <div className="col">
                  Original Image
                  </div>
                  <div className="col">
                  <img src={ApplicantDetails?.photo_url} alt="Original" height="150" width="150" />
                  </div>
                  </div>
                  <div className="row">
                  <div className="col">
                  Captured Image
                  </div>
                  <div className="col mT10">
                  <img src={ApplicantDetails?.UploadPhoto} alt="Original" height="150" width="150" />
                  </div>
                  </div>
                  <div className="row">
                  <div className="col">
                  Captured Video
                  </div>
                  <div className="col  mT10">
                    {
                      ApplicantDetails?.video_url ? (
                      <video width="320" height="240" controls>
                          <source src={ApplicantDetails?.video_url} type="video/webm" />
                        </video>) : 'N/A'
                    }
                 
                  </div>
                  </div>
                <div className="row">
                  <div className="col">
                  Applicant  Name
                  </div>
                  <div className="col">
                    {ApplicantDetails?.applicant_name}
                  </div>
                  </div>
                  <div className="row">
                  <div className="col">
                    Application Number
                  </div>
                  <div className="col">
                    {ApplicantDetails?.application_number}
                  </div>
                  </div>
                  <div className="row">
                    <div className="col">
                      Registration Number
                    </div>
                    <div className="col">
                      {ApplicantDetails?.registration_number}
                  </div>
                  </div>
                  {/* <div className="row">
                  <div className="col">
                    Roll Number
                  </div>
                  <div className="col">
                    {"value"}
                  </div>
                  </div> */}
                  <div className="row">
                  <div className="col">
                    Father Name
                  </div>
                  <div className="col">
                    {ApplicantDetails?.father_name}
                  </div>
                  </div>
                  <div className="row">
                  <div className="col">
                    Mother Name
                  </div>
                  <div className="col">
                    {ApplicantDetails?.mother_name}
                  </div>
                  </div>
                  <div className="row">
                  <div className="col">
                    Gender
                  </div>
                  <div className="col">
                    {ApplicantDetails?.gender}
                  </div>
                  </div>
                  <div className="row">
                  <div className="col">
                    Marital Status
                  </div>
                  <div className="col">
                    {ApplicantDetails?.marital_status}
                  </div>
                  </div>
                  <div className="row">
                  <div className="col">
                    Date of birth
                  </div>
                  <div className="col">
                    {ApplicantDetails?.dob}
                  </div>
                  </div>
                  <div className="row">
                  <div className="col">
                    Category
                  </div>
                  <div className="col">
                    {ApplicantDetails?.category}
                  </div>
                  </div>
                  <div className="row">
                  <div className="col">
                    Religion
                  </div>
                  <div className="col">
                    {ApplicantDetails?.religion}
                  </div>
                  </div>
                  <div className="row">
                  <div className="col">
                    Residents of Rajasthan
                  </div>
                  <div className="col">
                    {ApplicantDetails?.resident_of_rajasthan}
                  </div>
                  </div>
                  <div className="row">
                  <div className="col">
                    Home District
                  </div>
                  <div className="col">
                    {ApplicantDetails?.home_district}
                  </div>
                  </div>
                  <div className="row">
                  <div className="col">
                    Home State
                  </div>
                  <div className="col">
                    {ApplicantDetails?.home_state}
                  </div>
                  </div>
                  <div className="row">
                  <div className="col">
                    Nationality
                  </div>
                  <div className="col">
                    {ApplicantDetails?.nationality}
                  </div>
                  </div>
                  <div className="row">
                  <div className="col">
                    Address
                  </div>
                  <div className="col">
                    {ApplicantDetails?.address}
                  </div>
                  </div>
                  <div className="row">
                  <div className="col">
                    City
                  </div>
                  <div className="col">
                    {ApplicantDetails?.city}
                  </div>
                  </div>
                </div>
                <div class="modal-footer">
                  <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                </div>
              </div>
            </div>
          </div>
    </div>
  );
}

export default ReportPage;
