import React from "react";
import { Link } from "react-router-dom";

const ContactIcon = "/assets/images/footer_contact.svg";
const EmailIcon = "/assets/images/footer_email.svg";
const FooterLogo = "/assets/images/ID_logo.png";
const FooterVimeo = "/assets/images/footer_vimeo.svg";
const FooterLinkedIn = "/assets/images/footer_linkedIn.svg";
const FooterFacebook = "/assets/images/footer_facebook.svg";
const FooterTwitter = "/assets/images/footer_twitter.svg";
const GooglePlay = "/assets/images/google_play.svg";
const AppStore = "/assets/images/appstore.svg";

function Footer() {
  return (
    <div className="footer">
      <footer
        id="dc-footer"
        className="dc-footer dc-haslayout"
        style={{ backgroundColor: "#0B5A95" }}
      >
         <div className="dc-footertopbar">
          <div className="container">
            <div className="row justify-content-center align-self-center">
              <div className="col-xs-12 col-sm-12 col-md-12 col-lg-8 push-lg-2">
                <div className="dc-footer-call-email">
                  <div className="dc-callinfoholder">
                    <figure className="dc-callinfoimg">
                      <img src={ContactIcon} alt="img description" />
                    </figure>
                    <div className="dc-callinfocontent">
                      <h3>
                        <span>Contact No </span>
                        <Link to="">+916789432234</Link>
                      </h3>
                    </div>
                  </div>
                  <div className="dc-callinfoholder dc-mailinfoholder">
                    <figure className="dc-callinfoimg">
                      <img src={EmailIcon} alt="img description" />
                    </figure>
                    <div className="dc-callinfocontent">
                      <h3>
                        <span>Send us an Email </span>
                        <Link to="info@virtualpractice.com">
                          Support@identy.com
                        </Link>
                      </h3>
                    </div>
                  </div>
                  <span className="dc-or-text">&nbsp;</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="dc-fthreecolumns">
          <div className="container">
            <div className="row">
              <div className="col-xs-12 col-sm-12 col-md-6 col-lg-5 float-left">
                <div className="dc-fcol dc-widgetcontactus">
                  <strong className="dc-logofooter">
                    <Link to="">
                      <img src={FooterLogo} alt="company logo here" />
                    </Link>
                  </strong>
                  <div className="dc-footercontent">
                    <div className="dc-description">
                      <p>
                        Lorem Ipsum is simply dummy of the printing  and typesetting industry.Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.
                       {/*  <a
                          href="#!"
                          className="dc-detailsinfo dc-detailsinfofooter"
                        >
                          
                        </a> */}
                      </p>
                    </div>
                    {/* <ul className="dc-footercontactus">
                      <li>
                        <address>
                          <i className="lnr lnr-location"></i> 123 New Street,
                          Melbourne location Australia 54214
                        </address>
                      </li>
                      <li>
                        <a href="#!">
                          <i className="lnr lnr-envelope"></i>
                          info@domainurl.com
                        </a>
                      </li>
                      <li>
                        <span>
                          <i className="lnr lnr-phone-handset"></i> (0800) 1234
                          567891
                        </span>
                      </li>
                    </ul> */}
                  </div>
                </div>
              </div>
              <div className="col-xs-12 col-sm-6 col-md-6 col-lg-3 float-left">
                <div className="dc-fcol dc-flatestad">
                  <div className="dc-ftitle">
                    <h3>Quick Links</h3>
                  </div>
                  <div className="dc-footercontent">
                    <Link to="" style={{ color: "#ffffff" }}>
                      Home
                    </Link>
                    <br></br>

                    
                    <Link to="" style={{ color: "#ffffff" }}>
                     About Us
                    </Link>
                    <br></br>

                    <Link to="" style={{ color: "#ffffff" }}>
                     Contact Us
                    </Link>
                    <br></br>

                    
                    <Link to="" style={{ color: "#ffffff" }}>
                      Privacy Policy
                    </Link>
                    <br></br>
                    
                    
                    <Link to="" style={{ color: "#ffffff" }}>
                      Terms & Conditions
                    </Link>
                    <br></br>

                    <Link to="" style={{ color: "#ffffff" }}>
                     FAQ'S
                    </Link>
                    <br></br>
                  </div>
                </div>
              </div>
              <div className="col-xs-12 col-sm-6 col-md-6 col-lg-3 float-left">
                <div className="dc-fcol dc-flatestad">
                  <div className="dc-ftitle1">
                    <h3>Contact Us</h3>
                  </div>
                  <div className="dc-footercontent1">
                    <Link to="" style={{ color: "#ffffff" }}>
                     electroitsolutions1@gmail.com
                    </Link>
                    <div>
                      <Link to="">
                        <img
                          className="ml-2 mr-3"style={{    position: "relative",
                            top: "-28px",
                            left: "-30px"}}
                          src={FooterLinkedIn}
                          alt="linkedIn"
                        />
                        </Link>
                    </div>
                    <Link to="" style={{ color: "#ffffff" }}>
                     electroitsolutions1@gmail.com
                    </Link>
                   
                    <div>
                        <Link to="">
                          <img
                            className="ml-2 mr-3"style={{    position: "relative",
                              top: "-28px",
                              left: "-30px"}}
                            src={FooterLinkedIn}
                            alt="linkedIn"
                          />
                        </Link>
                      </div>

                    <Link to="" style={{ color: "#ffffff" }}>
                     (0141)2688774445
                    </Link>
                  
                    <div>
                        <Link to="">
                          <img
                            className="ml-2 mr-3"style={{    position: "relative",
                              top: "-28px",
                              left: "-30px"}}
                            src={FooterLinkedIn}
                            alt="linkedIn"
                          />
                        </Link>
                      </div>

                    
            
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="dc-footerbottom">
          <div className="container">
            <div className="row">
              <div className="col-12 col-sm-12">
                <p className="dc-copyright">
                  © Copyright 2021 E-kyc. All Rights Reserved.
                </p>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
}

export default Footer;
