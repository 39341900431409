import React, {useState,useEffect} from "react";
import HeaderUser from "../components/common/HeaderUser.component";
import Sidebar from "../components/common/Sidebar.component";
import makeTheApiCall from '../Api/axiosConfig'
import apiPath from '../Api/apiPath';
import {toast} from 'react-toastify'
import {useHistory} from 'react-router-dom';

function AllRoles() {
  const history= useHistory();
  const [TableData, setTableData] = useState(null);

  const DeleteProject = (id)=>{
    let response = window.confirm('Are you sure you want to delete ?');
    if(response){
      let apiOptions = {
        url :apiPath.Delete_Role,
        data :{
          user_id:id
        },
        method:"post"
      };
      makeTheApiCall(apiOptions).then((response)=>{
        let responseObj = response.success ? response?.success?.data : null;
        if(responseObj){
          toast.success('Role has been deleted');
          setTimeout(() => {
            window.location.reload();
          }, 2000);

        }else{
          toast.error('Please try again later');
        }
      });
    }
  };
  
  useEffect(() => {
    let apiOptions = {
      url :apiPath.getRoleDropDown,
      data :{
        page: 1,
        limit: 1000
      },
      method:"post"
    };
    makeTheApiCall(apiOptions).then((response)=>{
      let responseObj = response.success ? response?.success?.data : null;
      if(responseObj){
        setTableData(responseObj)
      }
    });
  }, []);
  return (
    <div className="home">
      <HeaderUser />
      <Sidebar />
      <div className="employeeWraper">
        <div className="headingSec">
          <h1>All Role</h1>
        </div>

        <div className="employyTable">
          <table>
            <thead className="">
              <tr className="">
              <th className="">#</th>
                <th className="">Name</th>
                <th className="">Created by</th>
                <th className="">Status</th>
                <th className="">Manage</th>
              </tr>
            </thead>
            <tbody>
              {
                TableData && TableData?.map((obj,index)=>{
                  return (
                    <tr key={index}>
                      <td>{index+1}</td>
                      <td> {obj?.name}</td>
                      <td> {obj?.name}</td>
                    <td>
                    <div class="form-check form-switch">
                        <input class="form-check-input" type="checkbox" id={obj?._id} checked={obj?.status == 1 ? true:false}/>
                        <label class="form-check-label" for={obj?._id}>{obj?.status == 1 ? "Active" : "Deactive"}</label>
                    </div>
                    </td>
                    <td>
                        {
                            obj?.is_edit ===1 && (
                              <span className="d-inline-block" tabindex={obj?._id} data-toggle="tooltip" title="Delete">
                                <button className="actionBtn" onClick={e=>DeleteProject(obj?._id)} >
                                    <span class="lnr lnr-trash"></span>
                                </button>
                                </span>
                            )
                        }
                      
                    </td>
                  </tr>
                  )
                })
              }
              <tr>
                <td className="blankTd"></td>
              </tr>
            </tbody>
          </table>

          {/* <div className="listingHeader">
           
          </div>
          <div className="tableBody">
            <div className="historyListing">
            
            </div>
            
          </div> */}
        </div>
      </div>
    </div>
  );
}

export default AllRoles;
