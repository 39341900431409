import React, { useEffect } from "react";
import {
  Route,
  Switch,
  Redirect,
  BrowserRouter,
  useHistory
} from "react-router-dom";
// import { Route as Router, Switch, Route,Redirect ,useHistory} from "react-router-dom";
import ScrollToTop from "./components/common/ScrollToTop.component";
import Home from "./pages/Home.page";
import About from "./pages/about";
import Contact from './pages/contact';
import Privacy from './pages/privacy';
import Terms from './pages/terms';
import Faq from './pages/faq';
import Link from './pages/link';
import Login from './components/auth/LoginModal.component';
// import Customer from './pages/custmer1';
import Customer from './pages/applicant-verify';
import Success from './pages/custmer4';
import Settings from "./pages/Settings.page";
import Dashboard from './pages/HomeEmployee.page'
import EditCustomers from './pages/EditCustomers.page';
import CustomerAll from './pages/AllCustomer'
import AllSubAdmins from './pages/AllSubAdmins'
import AddSubAdmin from './pages/AddSubAdmin'
import EditSubAdmins from './pages/EditSubAdmin'

import AllClients from './pages/allClients';
import AddClients from './pages/AddClient';
import EditClients from './pages/EditClient';

import AllProjects from './pages/AllProjects';
import AddProject from './pages/AddProject';
import EditProject from './pages/EditProject';
import { useUserState } from "./context/loginContext";
import Enquiry from "./pages/EnquiryList"
import Role from "./pages/AllRole";
import AddRole from "./pages/AddRole";
import Template from "./pages/EmailTemplate";
import SmsLogs from "./pages/SmsLogs";
import CustomerTrack from "./pages/CustomerTracks"
import Report from './pages/report.page'
const App = () => {

  var { isAuthenticated } = useUserState();
  
  const loading = (
    <div className="pt-3 text-center">
      <div className="sk-spinner sk-spinner-pulse"></div>
    </div>
  );
  return (
    <div className="app">
      <BrowserRouter>
      <React.Suspense fallback={loading}>
        <Switch>
          <Route
            exact
            path="/"
            name="Home Page"
            render={(props) =>
              <Home {...props} />
            }
          />
          <Route
            exact
            path="/link"
            name="link Page"
            render={(props) =>
              <Link {...props} />
            }
          />
          {/* <Route
            exact
            path="/about"
            name="Home Page"
            render={(props) =>
              <About {...props} />
            }
          /> */}
          {/* <Route
            exact
            path="/contact"
            name="Contact Page"
            render={(props) =>
              <Contact {...props} />
            }
          /> */}
          {/* <Route
            exact
            path="/privacy"
            name="privacy Page"
            render={(props) =>
              <Privacy {...props} />
            }
          /> */}
          {/* <Route
            exact
            path="/terms"
            name="terms Page"
            render={(props) =>
              <Terms {...props} />
            }
          /> */}
          {/* <Route
            exact
            path="/faq"
            name="faq Page"
            render={(props) =>
              <Faq {...props} />
            }
          /> */}
          <Route
            exact
            path="/login"
            name="faq Page"
            render={(props) =>
              !isAuthenticated ? (
                <Login {...props} />
              ) : (
                <Redirect
                  to={{
                    pathname: "/dashboard",
                    state: {
                      from: props.location,
                    },
                  }}
                />
              )
            }
          />

          <Route
            exact
            path="/customer-step"
            name="customer-step-1 Page"
            render={(props) =>
              (<Customer {...props} />)
            }
          />
          <Route
            exact
            path="/customer-verify-success"
            name="customer-verify-success Page"
            render={(props) =>
              <Success {...props} />
            }
          />

          <Route
            exact
            path="/dashboard"
            name="dashboard Page"
            render={(props) =>
              isAuthenticated ? (
                <Dashboard {...props} />
              ) : (
                <Redirect
                  to={{
                    pathname: "/",
                    state: {
                      from: props.location,
                    },
                  }}
                />
              )
            }
          />
          <Route
            exact
            path="/edit-customer"
            name="edit-customer Page"
            render={(props) =>
              isAuthenticated ? (
                <EditCustomers {...props} />
              ) : (
                <Redirect
                  to={{
                    pathname: "/",
                    state: {
                      from: props.location,
                    },
                  }}
                />
              )
            }
          />

          <Route
            exact
            path="/customers"
            name="customers Page"
            render={(props) =>
              isAuthenticated ? (
                <CustomerAll {...props} />
              ) : (
                <Redirect
                  to={{
                    pathname: "/",
                    state: {
                      from: props.location,
                    },
                  }}
                />
              )
            }
          />
          <Route
            exact
            path="/projects"
            name="projects Page"
            render={(props) =>
              isAuthenticated ? (
                <AllProjects {...props} />
              ) : (
                <Redirect
                  to={{
                    pathname: "/",
                    state: {
                      from: props.location,
                    },
                  }}
                />
              )
            }
          />

          <Route
            exact
            path="/add-project"
            name="add-projects Page"
            render={(props) =>
              isAuthenticated ? (
                <AddProject {...props} />
              ) : (
                <Redirect
                  to={{
                    pathname: "/",
                    state: {
                      from: props.location,
                    },
                  }}
                />
              )
            }
          />
          <Route
            exact
            path="/edit-project"
            name="edit-projects Page"
            render={(props) =>
              isAuthenticated ? (
                <EditProject {...props} />
              ) : (
                <Redirect
                  to={{
                    pathname: "/",
                    state: {
                      from: props.location,
                    },
                  }}
                />
              )
            }
          />
          <Route
            exact
            path="/sub-admin"
            name="sub-admin Page"
            render={(props) =>
              isAuthenticated ? (
                <AllSubAdmins {...props} />
              ) : (
                <Redirect
                  to={{
                    pathname: "/",
                    state: {
                      from: props.location,
                    },
                  }}
                />
              )
            }
          />
           <Route
            exact
            path="/add-sub-admin"
            name="add-sub-admin Page"
            render={(props) =>
              isAuthenticated ? (
                <AddSubAdmin {...props} />
              ) : (
                <Redirect
                  to={{
                    pathname: "/",
                    state: {
                      from: props.location,
                    },
                  }}
                />
              )
            }
          />
          <Route
            exact
            path="/edit-sub-admin"
            name="edit Page"
            render={(props) =>
              isAuthenticated ? (
                <EditSubAdmins{...props} />
              ) : (
                <Redirect
                  to={{
                    pathname: "/",
                    state: {
                      from: props.location,
                    },
                  }}
                />
              )
            }
          />
          <Route
            exact
            path="/enquiry"
            name="enquiry Page"
            render={(props) =>
              isAuthenticated ? (
                <Enquiry {...props} />
              ) : (
                <Redirect
                  to={{
                    pathname: "/",
                    state: {
                      from: props.location,
                    },
                  }}
                />
              )
            }
          />
          <Route
            exact
            path="/role"
            name="role Page"
            render={(props) =>
              isAuthenticated ? (
                <Role {...props} />
              ) : (
                <Redirect
                  to={{
                    pathname: "/",
                    state: {
                      from: props.location,
                    },
                  }}
                />
              )
            }
          />
          <Route
            exact
            path="/add-role"
            name="role Page"
            render={(props) =>
              isAuthenticated ? (
                <AddRole {...props} />
              ) : (
                <Redirect
                  to={{
                    pathname: "/",
                    state: {
                      from: props.location,
                    },
                  }}
                />
              )
            }
          />
          <Route
            exact
            path="/email-template"
            name="template Page"
            render={(props) =>
              isAuthenticated ? (
                <Template {...props} />
              ) : (
                <Redirect
                  to={{
                    pathname: "/",
                    state: {
                      from: props.location,
                    },
                  }}
                />
              )
            }
          />
          <Route
            exact
            path="/sms-logs"
            name="sms Page"
            render={(props) =>
              isAuthenticated ? (
                <SmsLogs {...props} />
              ) : (
                <Redirect
                  to={{
                    pathname: "/",
                    state: {
                      from: props.location,
                    },
                  }}
                />
              )
            }
          />
           <Route
            exact
            path="/settings"
            name="sms Page"
            render={(props) =>
              isAuthenticated ? (
                <Settings {...props} />
              ) : (
                <Redirect
                  to={{
                    pathname: "/",
                    state: {
                      from: props.location,
                    },
                  }}
                />
              )
            }
          />

           <Route
            exact
            path="/reports"
            name="report Page"
            render={(props) =>
              isAuthenticated ? (
                <Report {...props} />
              ) : (
                <Redirect
                  to={{
                    pathname: "/",
                    state: {
                      from: props.location,
                    },
                  }}
                />
              )
            }
          />
           <Route
            exact
            path="/customer-track"
            name="sms Page"
            render={(props) =>
              isAuthenticated ? (
                <CustomerTrack {...props} />
              ) : (
                <Redirect
                  to={{
                    pathname: "/",
                    state: {
                      from: props.location,
                    },
                  }}
                />
              )
            }
          />

          <Route
            exact
            path="/clients"
            name="clients Page"
            render={(props) =>
              isAuthenticated ? (
                <AllClients {...props} />
              ) : (
                <Redirect
                  to={{
                    pathname: "/",
                    state: {
                      from: props.location,
                    },
                  }}
                />
              )
            }
          />
           <Route
            exact
            path="/add-clients"
            name="clients Page"
            render={(props) =>
              isAuthenticated ? (
                <AddClients {...props} />
              ) : (
                <Redirect
                  to={{
                    pathname: "/",
                    state: {
                      from: props.location,
                    },
                  }}
                />
              )
            }
          />
          <Route
            exact
            path="/edit-clients"
            name="clients edit Page"
            render={(props) =>
              isAuthenticated ? (
                <EditClients{...props} />
              ) : (
                <Redirect
                  to={{
                    pathname: "/",
                    state: {
                      from: props.location,
                    },
                  }}
                />
              )
            }
          />
        </Switch>
      </React.Suspense>
    </BrowserRouter>
    </div>
  );
};

export default App;
